import React from "react";
import AdjustArincareMaintenance from 'Images/arincare_notify_close_maintenance.png';
import AdjustArincareMalfunction from 'Images/arincare_notify_close_malfunction.png';
import { Button } from 'reactstrap';
import { dateFormat, timeFormat, DateTime } from 'Components/Transform/DateTimeFormat';

class NotifyMaintenance extends React.Component {
    refreshPage() {
        window.location.reload(false);
    }

    maintenanceNotifyPlatform() {
        return (
            <>
                <link href="https://fonts.googleapis.com/css?family=Kanit" rel="stylesheet"/>
                <div className="container">
                    <div className="column-adjust-left">
                        <div className="body-content-adjust-0">
                            ขออภัยค่ะ
                        </div>
                        <div className="body-content-adjust-1">
                            {
                            process.env.CLOSE_PLATFORM_TYPE === 'MALFUNCTION'
                            ?
                                <>ระบบไม่สามารถใช้งานได้ในขณะนี้</>
                            :    
                                <>ระบบไม่สามารถใช้งานได้ชั่วคราว</>
                            }
                        </div>
                        <div className="body-content-adjust-1-sub">
                            {
                            process.env.CLOSE_PLATFORM_TYPE === 'MAINTENANCE'
                            ?
                                <>
                                เนื่องจากมีการปิดปรับปรุงเพื่อพัฒนาระบบ
                                </>
                            :    
                                <>
                                เนื่องจากระบบขัดข้อง ทีมงานกำลังแก้ไข<br/>ขออภัยในความไม่สะดวก ณ ที่นี้ กรุณาลองใหม่ ในภายหลัง
                                </>
                            }
                        </div>
                        {
                        process.env.CLOSE_PLATFORM_TYPE === 'MALFUNCTION'
                        ?
                            <div className="body-content-adjust-2">
                                เวลา { timeFormat(DateTime.startTime) } น. 
                                วันที่ { dateFormat(DateTime.startDate) } 
                            </div>
                        :    
                            <div className="body-content-adjust-2">
                                ตั้งเเต่เวลา { timeFormat(DateTime.startTime) } น. 
                                วันที่ { dateFormat(DateTime.startDate) }<br/>
                                ถึงเวลา  { timeFormat(DateTime.endTime) } น. 
                                วันที่ { dateFormat(DateTime.endDate) }   
                            </div>
                        }
                        <div>
                            <Button className="button-adjust" color="primary" onClick={ this.refreshPage }>
                            คลิกที่นี่ เพื่อโหลดใหม่
                            </Button>
                        </div>
                    </div>
                    <div className="column-adjust-right">
                        <div className="body-adjust-img">
                        {
                        process.env.CLOSE_PLATFORM_TYPE === 'MAINTENANCE'
                        ?
                            <img src={ AdjustArincareMaintenance } className="img-resize img-center"></img>
                        :    
                            <img src={ AdjustArincareMalfunction } className="img-resize img-center"></img>
                        }
                        </div>
                    </div>
                </div>
            </>
        )

    }

    render() {
        return (
            this.maintenanceNotifyPlatform()
        );
    }
}

export default NotifyMaintenance