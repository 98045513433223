import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ArincareWillLaunchNewFeature from 'Images/arincare_will_launch_new_feature.png';
import { dateFormat,  DateTime } from '../Transform/DateTimeFormat';
class NotiWindowUpdatePlatform extends React.Component {
    constructor(props) {
        super(props);
        this.handleModalNotifyUpdatePlatform = this.handleModalNotifyUpdatePlatform.bind(this);
        this.state = {
            modal: true
        }
    }
    handleModalNotifyUpdatePlatform() {
        const { employee } = this.props;
        const today = new Date().toISOString().split('T')[0];

        this.setState({
            modal: !this.state.modal,
        }, () => {
            if (!this.state.modal && employee && employee.id) {
                const lastShownData = this.getLastShownData('lastModalNotiWindowUpdatePlatformShown');
                lastShownData[employee.id] = today;
                localStorage.setItem('lastModalNotiWindowUpdatePlatformShown', JSON.stringify(lastShownData));
            }
        })
    }

    componentDidMount() {
        const { employee } = this.props;
        

        if (employee && employee.id) {
            const today = new Date().toISOString().split('T')[0]; 
            const lastShownData = this.getLastShownData('lastModalNotiWindowUpdatePlatformShown');
            const lastShownDate = lastShownData[employee.id]; 
            if (lastShownDate !== today) {
                this.setState({ modal: true });
            } else {
                this.setState({ modal: false });
            }
        }
    }

    getLastShownData(lsVar){
        const lastShownDataRaw = localStorage.getItem(lsVar);
        let lastShownData = {};
        if (lastShownDataRaw) {
            try {
                lastShownData = JSON.parse(lastShownDataRaw);
            } catch (error) {
                console.error(`Invalid JSON in ${lsVar}:`, error);
                lastShownData = {};
            }
        }
        return lastShownData;
    }
    

    notifyUpdatePlatform() {
        const rawHTML = '<ul><li><strong>จัดการข้อมูลพิมพ์ฉลากยา ในหน้าหลังบ้าน (APP)</strong>  <br/>หัวข้อ ข้อมูลสินค้า > สำหรับพิมพ์</li><li><strong>จัดการข้อมูลพิมพ์ฉลากยา ในหน้าขาย (POS)</strong></li><li><strong>การพิมพ์ฉลากยาเปล่า ในหน้าหลังบ้าน (APP)</strong> <br/>หัวข้อ ข้อมูลสินค้า > จัดการฉลากยา</li></ul>';
        const updateFeatureLinkManual  = process.env.UPDATE_PLATFORM_LINK_MANUAL || ''; 
        const rawFeatureLinkManual = updateFeatureLinkManual 
        ? `<a href="${updateFeatureLinkManual}" target="_blank" title="คลิกเพื่อดูข้อมูลยา"> ***คู่มือการใช้งาน*** </a>` 
        : '';
        
        return (
            
            <>
                <link href="https://fonts.googleapis.com/css?family=Kanit" rel="stylesheet"/>
                <div>
                    <Modal isOpen={ this.state.modal } databackdrop="static" datakeyboard="false">
                        <ModalHeader className="close-platform-header">
                            แจ้งอัปเดต
                        </ModalHeader>
                        <ModalBody>
                            <div className="img-body-close-platform">
                                <img src={ ArincareWillLaunchNewFeature } />
                            </div>
                            <div className="modal-body-close-platform-0-orange">
                                แจ้งอัพเดทระบบ System Maintenance
                            </div>
                            <div className="modal-body-close-platform-1">
                                ในวันที่ {DateTime.startUpdateDate ? dateFormat(DateTime.startUpdateDate) : 'วันที่ไม่ระบุ'} 
                            </div>
                            <div className='modal-body-close-platform-2-left'>
                                หัวข้อที่อัปเดต มีดังนี้<br/>
                                <div dangerouslySetInnerHTML={{ __html: rawHTML }}></div>
                            </div>
                            <div className="modal-body-close-platform-3">
                                <div dangerouslySetInnerHTML={{ __html: rawFeatureLinkManual }}></div>
                            </div>
                        </ModalBody>
                        <ModalFooter className="modal-footer-close-platform">
                            <Button outline color="danger" onClick={ this.handleModalNotifyUpdatePlatform }>
                                รับทราบแล้ว
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );      
    }
    render() {
        return (
            this.notifyUpdatePlatform()
        );
    }
}

const mapStateToProps = state => ({
    employee: state.employee.employee,
});

export default connect(mapStateToProps)(NotiWindowUpdatePlatform);