import React from 'react';
import { Col, Row } from 'reactstrap';
import is from 'is_js';

import CustomerInfoForm from 'Components/Form/CustomerInfoForm';
import AllergyInfoForm from 'Components/Form/AllergyInfoForm';

class CustomerRegisterForm extends React.Component {
    state = {
        loading: false,
        errors: {},
    };

    _registerCustomer = () => {
        const { errors, isValid } = this._validate(this.props.customer.data, this.props.language);
        if (!isValid) {
            this.setState({ errors });
        } else {
            this.setState({ loading: true, errors: {} });
            this.props.onSubmit().then(() => {
                this.setState({ loading: false });
            });
        }
    };

    render() {
        const { onCancel, language } = this.props;
        return (
            <>
                <CustomerInfoForm
                    language={language}
                    setCustomerValue={this.props.setCustomerValue}
                    customer={this.props.customer}
                    errors={this.state.errors}
                />
                <AllergyInfoForm
                    language={language}
                    customer={this.props.customer}
                    addAllergyInfo={this.props.addAllergyInfo}
                    removeAllergyInfo={this.props.removeAllergyInfo}
                    getAllergy={this.props.getAllergy}
                    clearAllergy={this.props.clearAllergy}
                    handleAllergyChange={this.props.handleAllergyChange}
                />
                <Row className="mt-4">
                    <Col md={{ size: 3, offset: 3 }}>
                        <button
                            type="button"
                            className="btn btn-danger btn-block"
                            onClick={onCancel}>{language.cancel}</button>
                    </Col>
                    <Col md="3">
                        <button
                            type="button"
                            className="btn btn-success btn-block"
                            onClick={this._registerCustomer}
                            disabled={this.state.loading}>
                            {this.state.loading ? `${language.saving}...` : language.save}
                        </button>
                    </Col>
                </Row>
            </>
        );
    }

    _validate = (data, lang) => {
        let errors = {};
        const patternDate = (/(1[89]|2[0-6])\d{2}-(0?[1-9]|1[0-2])-(0?[1-9]|[12]\d|3[01]){1}$/);

        is.empty(data.first_name) ? errors.first_name = lang.input_required : '';
        is.empty(data.last_name) ? errors.last_name = lang.input_required : '';
        is.empty(data.mobile_number) ? errors.mobile_number = lang.input_required :
            is.not.number(Number(data.mobile_number)) ? errors.mobile_number = lang.numbers_only : '';
        is.empty(data.birth_date) ? errors.birth_date = lang.input_required : !patternDate.test(data.birth_date) ? errors.birth_date = lang.date_invalid : '';
        is.empty(data.email) ? '' : is.not.email(data.email) ? errors.email = lang.email_invalid : '';
        is.empty(data.sex) ? errors.sex = lang.select_required : '';

        is.empty(data.nationality) ? errors.nationality = lang.select_required : '';
        is.empty(data.price_level) ? errors.price_level = lang.select_required : '';

        return {
            errors,
            isValid: is.empty(errors),
        };
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.showCustomerRegister) {
            this.setState({ errors: {} });
        }
    }
}

export default CustomerRegisterForm;
