import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, CardHeader, Input, Table } from 'reactstrap';
import {
  searchCustomers,
  closeSearchCustomers,
  openPrescriptionInfoModal,
  openPickupPrescriptionModal,
  openCancelPrescriptionModal,
  closeCancelPrescriptionModal,
  confirmCancelPrescription,
  changePrescriptionPickupNote,
  clearBodc12Data,
  selectCustomer,
  unSelectCustomer,
  openCustomerRegister,
  closeCustomerRegister,
  setCustomerValue,
  getCustomerInfo,
  closeCustomerInfo,
  getSalesOrders,
  addAllergyInfo,
  removeAllergyInfo,
  getAllergy,
  clearAllergy,
  registerCustomer,
  editInfoCustomer,
  handleAllergyChange,
  getTableHeader
} from 'Redux/actions';

import SearchCustomerModal from 'Components/Modal/SearchCustomerModal';
import CustomerRegister from 'Components/Modal/CustomerRegister';
import CustomerInfo from 'Components/Modal/CustomerInfo';
import PrescriptionPickupModal from 'Components/Modal/PrescriptionPickupModal';
import PrescriptionInfoModal from 'Components/Modal/PrescriptionInfoModal';
import PrescriptionCancelModal from 'Components/Modal/PrescriptionCancelModal';
import PrescriptionModelModal from 'Components/Modal/PrescriptionModelModal';

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
      loading: false,
      getInfo: false,
      showModelModal: false,
    };
    this._onChangeSearch = this._onChangeSearch.bind(this)
    this._handleFormSubmit = this._handleFormSubmit.bind(this)
  }

  _onChangeSearch(e) {
    this.setState({ query: e.target.value })
  }

    onChangePrescriptionPickupNote = (e) => {
        this.props.changePrescriptionPickupNote(e.target.value)
    };

  _handleFormSubmit(e) {
    e.preventDefault()
    this.setState({ loading: true })
    this.props.searchCustomers(this.state.query).then(() => {
      this.setState({ query: '', loading: false })
    })
  }

  _getCustomerInfo = () => {
      const { customer } = this.props;
    this.setState({ getInfo: true })
    return this.props.getCustomerInfo(customer.id).then(res => {
      !res.customerFailed && this.props.getSalesOrders()
      this.setState({ getInfo: false })
    })
  }

  render() {
    const {
      language,
      customer,
      cart,
      prescription,
      prescriptionPickupNote,
      unSelectCustomer,
      openCustomerRegister,
      settings
    } = this.props;

    let productAllergicList = <tr><td>-</td></tr>
    let ingredientAllergicList = <tr><td>-</td></tr>
    let customerDiseases = <tr><td>-</td></tr>

    if(customer.product_allergies && customer.product_allergies.length > 0) {
      productAllergicList = customer.product_allergies.map(item => <tr><td>{item.product_name}</td></tr>)
    }

    if(customer.ingredient_allergies && customer.ingredient_allergies.length > 0) {
      ingredientAllergicList = customer.ingredient_allergies.map(item => <tr><td>{item.ingredient_name}</td></tr>)
    }

    if(customer.customer_diseases && customer.customer_diseases.length > 0) {
      customerDiseases = customer.customer_diseases.map(item => <tr><td>{item.disease_name}</td></tr>)
    }

    return (
      <div className="my-2 d-flex flex-column flex-grow-1">
        <form onSubmit={this._handleFormSubmit}>
          {settings.offline_mode ?
          <input
            type="text"
            className="form-control"
            value={this.state.query}
            onChange={this._onChangeSearch}
            onKeyDown={this._handleKeyDown}
            ref="searchCustomer"
            placeholder={language.search_customer}
          /> :
          <div className="input-group">
            <input
              type="text"
              className="form-control"
              value={this.state.query}
              onChange={this._onChangeSearch}
              ref="searchCustomer"
              placeholder={language.search_customer}
            />
            <div className="input-group-append">
              <button
                type="button"
                className="btn btn-primary"
                onClick={openCustomerRegister}>
                <i className="fa fa-user-plus" /> {language.register_member}
              </button>
            </div>
          </div>}
        </form>
        <div className="customer-container flex-grow-1">
          {customer.id && (
          <Card className={'mb-4'}>
            <CardHeader className="info">
                Customer
                <div style={{ position: 'absolute', top: 8, right: 8 }}>
                    {!settings.offline_mode && <Button
                        className="mr-2"
                        color="warning"
                        size="sm"
                        onClick={this._getCustomerInfo}
                        disabled={this.state.loading}
                    >
                        {this.state.loading
                            ? `${language.loading}...`
                            : language.view_profile}
                    </Button>}
                    <Button color="danger" size="sm" onClick={unSelectCustomer}>
                        {language.cancel}
                    </Button>
                </div>
            </CardHeader>
            <Table className="mb-0">
              <tbody>
                <tr>
                  <td>{language.member_code}</td>
                  <td>{customer.reference_code}</td>
                </tr>
                <tr>
                  <td>{language.member_name}</td>
                  <td>{customer.name}</td>
                </tr>
                {customer.is_hato_costomers === true  && (
                  <>
                    <tr>
                      <td>{language.member_coupons}</td>
                      <td>{customer.hato_costomers_coupon}</td>
                    </tr>
                    <tr>
                      <td>{language.member_points}</td>
                      <td>{customer.hato_costomers_points}</td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </Card>
          )}
          { customer.id && productAllergicList && (
              <Card className={'mb-4 member-medical'}>
              <CardHeader className="info">
                  {language.product_allergic}
              </CardHeader>
              <Table className="mb-0">
                <tbody>
                  {productAllergicList}
                </tbody>
              </Table>
            </Card>
            )
          }
          { customer.id && ingredientAllergicList && (
            <Card className={'mb-4 member-medical'}>
              <CardHeader className="info">
              {language.generic_name}
              </CardHeader>
              <Table className="mb-0">
              <tbody>
              {ingredientAllergicList}
              </tbody>
              </Table>
            </Card>
          )}
          {customer.id && customerDiseases && (
            <Card className={'mb-4 member-medical'}>
              <CardHeader className="info">
              {language.customer_diseases}
              </CardHeader>
              <Table className="mb-0">
              <tbody>
              {customerDiseases}
              </tbody>
              </Table>
            </Card>
          )}
            {!!prescription && (
              <React.Fragment>
                <Card className="mt-2">
                    <CardHeader className="info">
                        e-Prescription
                        <div style={{ position: 'absolute', top: 8, right: 8 }}>
                            <Button
                                className="mr-2"
                                color="warning"
                                size="sm"
                                onClick={this.props.openPrescriptionInfoModal}
                            >
                                {language.view_profile}
                            </Button>
                            <Button
                                color="danger"
                                size="sm"
                                onClick={this.props.openCancelPrescriptionModal}
                            >
                                {language.cancel}
                            </Button>
                        </div>
                    </CardHeader>
                    <Table className="mb-0">
                        <tbody>
                            <tr>
                                <td>{language.reference_code}</td>
                                <td>{prescription.reference_code}</td>
                            </tr>
                            <tr>
                                <td>{language.patient_name}</td>
                                <td>{prescription.patient_first_name} {prescription.patient_last_name}</td>
                            </tr>
                            <tr>
                                <td>{language.prescriber_name}</td>
                                <td>{prescription.prescriber_first_name} {prescription.prescriber_last_name}</td>
                            </tr>
                            <tr>
                                <td>{language.hospital}</td>
                                <td>{prescription.prescriber_company_name}</td>
                            </tr>
                            <tr>
                                <td>Model สปสช.</td>
                                <td>
                                    <a style={prescriptionModelStyle} onClick={() => this.setState({ showModelModal:true })}>{prescription.model ? prescription.model.name : prescription.model_no}</a>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <Input
                                        type="textarea"
                                        placeholder="หมายเหตุการรับยา..."
                                        onChange={this.onChangePrescriptionPickupNote}
                                        value={prescriptionPickupNote}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card>
                <Card className="mt-2">
                  <CardHeader className="info">
                    {language.pickup_drug}: {prescription.prescription_pickup_types && prescription.prescription_pickup_types.name ? prescription.prescription_pickup_types.name : '-'}
                  </CardHeader>
                    <Table className="mb-0">
                        <tbody>
                            {prescription.pickup_type === 'MESSENGER_PICKUP' && <React.Fragment>
                              <tr>
                                <td width="200">{language.messenger_info}</td>
                                <td>{prescription.messenger_name || '-'}</td>
                              </tr>
                              <tr>
                                <td width="200">{language.messenger_phone}</td>
                                <td>{prescription.messenger_phone || '-'}</td>
                              </tr>
                            </React.Fragment>}
                            <tr>
                              <td width="200">{language.pickup_note}</td>
                              <td>{prescription.address_note || '-'}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Card>
              </React.Fragment>
            )}
            {!!cart.bodc12 && (
                <Card className="mt-2">
                    <CardHeader className="info">
                        Prescription
                        <div style={{ position: 'absolute', top: 8, right: 8 }}>
                            <Button
                                className="mr-2"
                                color="warning"
                                size="sm"
                                onClick={this.props.openPickupPrescriptionModal}
                            >
                                {language.edit}
                            </Button>
                            <Button
                                color="danger"
                                size="sm"
                                onClick={this.props.clearBodc12Data}
                            >
                                {language.cancel}
                            </Button>
                        </div>
                    </CardHeader>
                    <Table className="mb-0">
                        <tbody>
                            <tr>
                                <td>{language.hospital}</td>
                                <td>{cart.bodc12.hospital_name}</td>
                            </tr>
                            <tr>
                                <td>{language.prescriber_name}</td>
                                <td>{cart.bodc12.prescriber_name}</td>
                            </tr>
                            <tr>
                                <td>{language.patient_name}</td>
                                <td>{cart.bodc12.patient_name}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Card>
            )}
        </div>
        <SearchCustomerModal
          showSearchCustomers={this.props.showSearchCustomers}
          customers={this.props.customers}
          selectCustomer={this.props.selectCustomer}
          language={language}
          closeSearchCustomers={this.props.closeSearchCustomers}
        />
        <CustomerRegister
          showCustomerRegister={this.props.showCustomerRegister}
          closeCustomerRegister={this.props.closeCustomerRegister}
          language={language}
          setCustomerValue={this.props.setCustomerValue}
          customer={this.props.customer}
          addAllergyInfo={this.props.addAllergyInfo}
          removeAllergyInfo={this.props.removeAllergyInfo}
          getAllergy={this.props.getAllergy}
          clearAllergy={this.props.clearAllergy}
          registerCustomer={this.props.registerCustomer}
          handleAllergyChange={this.props.handleAllergyChange}
        />
        <CustomerInfo
          showCustomerInfo={this.props.showCustomerInfo}
          closeCustomerInfo={this.props.closeCustomerInfo}
          language={language}
          customerInfo={this.props.customerInfo}
          getSalesOrders={this.props.getSalesOrders}
          editInfoCustomer={this.props.editInfoCustomer}
          getTableHeader={this.props.getTableHeader}

          // For register
          getCustomerInfo={this._getCustomerInfo}
          registerCustomer={this.props.registerCustomer}
          closeCustomerRegister={this.props.closeCustomerRegister}
          setCustomerValue={this.props.setCustomerValue}
          customer={this.props.customer}
          addAllergyInfo={this.props.addAllergyInfo}
          removeAllergyInfo={this.props.removeAllergyInfo}
          getAllergy={this.props.getAllergy}
          clearAllergy={this.props.clearAllergy}
          handleAllergyChange={this.props.handleAllergyChange}
        />
        <PrescriptionPickupModal />
        <PrescriptionInfoModal />
        <PrescriptionCancelModal
            isOpen={this.props.showPrescriptionCancelModal}
            prescription={this.props.prescription}
            onClose={this.props.closeCancelPrescriptionModal}
            onSubmit={this.props.confirmCancelPrescription}
        />
        <PrescriptionModelModal
            show={this.state.showModelModal}
            language={language}
            prescription={this.props.prescription}
            onClose={() => this.setState({ showModelModal: false })}
        />
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    const { focus } = this.props;
    const { focus: wasfocus } = prevProps;
    if (!wasfocus && focus) {
      this.refs.searchCustomer.focus();
    }
  }
}

const prescriptionModelStyle = {
    color: '#007bff',
    cursor: 'pointer',
}

const mapStateToProps = state => {
  let customers = Object.assign([], state.search.customers);
  return {
    customers,
    customer: state.customer,
    cart: state.cart,
    prescription: state.prescription.data,
    prescriptionPickupNote: state.prescription.pickupNote,
    language: state.language,
    showSearchCustomers: state.modal.showSearchCustomers,
    showCustomerRegister: state.modal.showCustomerRegister,
    showCustomerInfo: state.modal.showCustomerInfo,
    showPrescriptionCancelModal: state.prescription.cancelModalOpen,
    customerInfo: state.customerInfo,
    focus: state.focus.searchCustomer,
    settings: state.settings
  };
};

export default connect(
  mapStateToProps,
  {
    searchCustomers,
    closeSearchCustomers,
    openPrescriptionInfoModal,
    openPickupPrescriptionModal,
    openCancelPrescriptionModal,
    closeCancelPrescriptionModal,
    confirmCancelPrescription,
    changePrescriptionPickupNote,
    clearBodc12Data,
    selectCustomer,
    unSelectCustomer,
    openCustomerRegister,
    closeCustomerRegister,
    setCustomerValue,
    getCustomerInfo,
    closeCustomerInfo,
    getSalesOrders,
    addAllergyInfo,
    removeAllergyInfo,
    getAllergy,
    clearAllergy,
    registerCustomer,
    editInfoCustomer,
    handleAllergyChange,
    getTableHeader
  }
)(Customer);
