import React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ArincareClosePlatform from 'Images/arincare_will_close_platform.png';
import { dateFormat, timeFormat, DateTime } from '../Transform/DateTimeFormat';

class NotiWindowWillClosePlatform extends React.Component {
    constructor(props) {
        super(props);
        this.handleModal = this.handleModal.bind(this);
        this.state = {
            modal: true
        }
    }

    handleModal() {
        const { employee } = this.props;
        const today = new Date().toISOString().split('T')[0];

        this.setState({
            modal: !this.state.modal,
        }, () => {
            if (!this.state.modal && employee && employee.id) {
                const lastShownData = this.getLastShownData('lastModalNotiWindowWillClosePlatformShown');
                lastShownData[employee.id] = today; // บันทึกวันที่วันนี้ลงไปสำหรับพนักงานคนนั้น
                localStorage.setItem('lastModalNotiWindowWillClosePlatformShown', JSON.stringify(lastShownData));
            }
        })
    }

    componentDidMount() {
        const { employee } = this.props;

        if (employee && employee.id) {
            const today = new Date().toISOString().split('T')[0];
            const lastShownData = this.getLastShownData('lastModalNotiWindowWillClosePlatformShown');
            const lastShownDate = lastShownData[employee.id]; 

            if (lastShownDate !== today) {
                this.setState({ modal: true });
            } else {
                this.setState({ modal: false });
            }
        }
    }

    getLastShownData(lsVar){
        const lastShownDataRaw = localStorage.getItem(lsVar);
        let lastShownData = {};
        if (lastShownDataRaw) {
            try {
                lastShownData = JSON.parse(lastShownDataRaw);
            } catch (error) {
                console.error(`Invalid JSON in ${lsVar}:`, error);
                lastShownData = {};
            }
        }
        return lastShownData;
    }

    notifyPlatform() {
        return (
            <>
                <link href="https://fonts.googleapis.com/css?family=Kanit" rel="stylesheet"/>
                <div>
                    <Modal isOpen={ this.state.modal } databackdrop="static" datakeyboard="false">
                        <ModalHeader className="close-platform-header">
                            แจ้งเตือนการปิดปรับปรุงระบบ
                        </ModalHeader>
                        <ModalBody>
                            <div className="img-body-close-platform">
                                <img src={ ArincareClosePlatform } />
                            </div>
                            <div className="modal-body-close-platform-0">
                                ประกาศ ปิดปรับปรุงระบบชั่วคราว
                            </div>
                            <div className="modal-body-close-platform-1">
                                ตั้งเเต่เวลา { timeFormat(DateTime.startTime) } น. 
                                วันที่ { dateFormat(DateTime.startDate) }<br/>
                                ถึงเวลา  { timeFormat(DateTime.endTime) } น. 
                                วันที่ { dateFormat(DateTime.endDate) }
                            </div>
                            <div className="modal-body-close-platform-2">
                                เพื่อเพิ่มประสิทธิภาพการให้บริการ<br/>
                                โดยผู้ใช้งานจะไม่สามารถเข้าใช้งานระบบได้<br/>ในช่วงเวลาดังกล่าว
                            </div>
                            <div className="modal-body-close-platform-3">
                                ขออภัยในความไม่สะดวกมา ณ ที่นี้<br/>
                                หากมีข้อสงสัยเพิ่มเติม ติดต่อได้ที่ Line ID : @arincare
                            </div>
                        </ModalBody>
                        <ModalFooter className="modal-footer-close-platform">
                            <Button outline color="danger" onClick={ this.handleModal }>
                                รับทราบแล้ว
                            </Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </>
        );      
    }
    render() {
        return (
            this.notifyPlatform()
        );
    }
}

const mapStateToProps = state => ({
    employee: state.employee.employee,
});

export default connect(mapStateToProps)(NotiWindowWillClosePlatform);