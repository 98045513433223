import {
    CLOSE_DANGER_MED_MODAL,
    SET_VALUE_IN_DG_MED,
    SUBMIT_DANGER_MED,
    FOCUS_DANGER_MED,
    OPEN_MEDAD_MODAL,
}
from 'Redux/actionTypes';
import { dispatchCheckout } from './checkout'


export const closeDangerModal = () => dispatch => {
    return dispatch({ type: CLOSE_DANGER_MED_MODAL});
}

export const submitDangerModal = () => (dispatch, getState) => {

    dispatch({ type: SUBMIT_DANGER_MED});

    const { settings } = getState();
    const { is_medad } = settings;
    if(is_medad){
        console.log('is_medad = '+is_medad);
        return dispatch({
            type: OPEN_MEDAD_MODAL,
            payload: is_medad,
        });
    }
    return dispatch(dispatchCheckout());
}

export const focusDgMed = () => dispatch => {
    return dispatch({ type: FOCUS_DANGER_MED})
}

export const setValueInDGMed = ( path, value) => dispatch => {
    return dispatch({
        type: SET_VALUE_IN_DG_MED,
        payload:{
            path,
            value
        }
    })
}
