import React from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { UncontrolledTooltip } from 'reactstrap'

import { getTableHeader
, removeToCart
, printDrugLabel
, editCartDetail
, closePrintDrugLabel
, resetPrintDrugLabel
, submitPrintDrungLabel
, setDrugLabelValue
, switchLanguage
, selectTimeToEat 
, upIndexCart
, downIndexCart
, setCartValue
, onBlurCart 
, addToWishList
, removeWishList
, checkCouponWhenUpdatedCart
, triggerCheckout
, submitNotiRefreshModal
, closeNotiRefreshModal } from 'Redux/actions'

import ACTable from 'Components/DataGrid/Table';
import PrintDrugLabel from 'Components/Modal/PrintDrugLabel';
import CartShortcuts from 'Components/Shortcuts/CartShortcuts';
import NotiRefreshModal from 'Components/Modal/NotiRefreshModal';
import PrescriptionCartInfo from 'Components/Form/PrescriptionCartInfo';
import NotiWillClosePlatform from '../components/Modal/NotiWillClosePlatform';
import NotiUpdatePlatform from '../components/Modal/NotiUpdatePlatform';

class Cart extends React.Component{
    constructor(props){
        super(props)
        this._renderCell = this._renderCell.bind(this);
        this._renderChildCell = this._renderChildCell.bind(this);
        this._renderChildPrice = this._renderChildPrice.bind(this);
        this._renderProductName = this._renderProductName.bind(this);
        this._renderRemark = this._renderRemark.bind(this);
        this._editWithShortcut = this._editWithShortcut.bind(this);
        this._deleteWithShortcut = this._deleteWithShortcut.bind(this);
        this._printDrugLabelWithShortcut = this._printDrugLabelWithShortcut.bind(this);
        this._switchLangauge = this._switchLangauge.bind(this);
        this.state = {
            headers: []
        }
    }

    _renderCell(data){
        const { removeToCart, editCartDetail, printDrugLabel, settings, prescription } = this.props;
        const isLinemanEp = prescription && prescription.category == 'lm';
        const isMedcareEp = prescription && prescription.category == 'mc';

        return(

            <div className='text-center'>
                <i className="fa fa-trash fa-2x mr-3 text-danger link"
                   onClick={() => {
                       setTimeout(() => {
                           removeToCart(data);
                       });
                   }}
                   title={this.props.language.cancel}
                />

                {!data.is_system_product && (
                    <>
                        {data.product_set_type !== '1' && (
                            <i className="fa fa-print fa-2x mr-3 text-primary link"
                               onClick={() => {
                                   setTimeout(() => {
                                       printDrugLabel(data.product);
                                   });
                               }}
                               title={this.props.language.print_label}
                            />
                        )}

                        {data.product_set_type !== '1' && !isLinemanEp && !isMedcareEp && (
                            <i
                                className="fa fa-pencil-square-o fa-2x mr-3 text-success link"
                                onClick={() => {
                                    setTimeout(() => {
                                        editCartDetail(data);
                                    });
                                }}
                                title={this.props.language.increase_item}
                            />
                        )}

                        {/* 
                        {data.product_set_type !== '1' && !settings.offline_mode && (
                            data.product.wishlist_item ? (
                                <span>
                                    <i
                                        className="fa fa-check-circle fa-2x text-primary link"
                                        id={'Tooltip-' + data.product.reference_code}
                                        onClick={() => this.props.removeWishList(data.product, 'cart')}
                                    />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target={'Tooltip-' + data.product.reference_code}>
                                        Remove from Wish List
                                    </UncontrolledTooltip>
                                </span>
                            ) : (
                                <span>
                                    <i
                                        className="fa fa-heart fa-2x text-danger link"
                                        id={'Tooltip-' + data.product.reference_code}
                                        onClick={() => this.props.addToWishList(data.product, 'cart')}
                                    />
                                    <UncontrolledTooltip
                                        placement="top"
                                        target={'Tooltip-' + data.product.reference_code}
                                    >
                                        Add to Wish List
                                    </UncontrolledTooltip>
                                </span>
                            )
                        )} */
                        }
                    </>
                )}
            </div>
        )
    }
    _renderChildCell(data){
        const { printDrugLabel, settings } = this.props;
        return(
            <div className='text-center'>
                <i className="fa fa-print fa-2x mr-3 text-primary link"
                onClick={ () => { setTimeout(() => { printDrugLabel(data); }) } }
                title={this.props.language.print_label}></i>
                {
                /* {!settings.offline_mode ?
                data.wishlist_item ?
                <span>
                    <i className="fa fa-check-circle fa-2x text-primary link"
                    id={'Tooltip-' + data.reference_code}
                    onClick={() => this.props.removeWishList(data, 'cart')}></i>
                    <UncontrolledTooltip placement="top" target={'Tooltip-' + data.reference_code}>Remove from Wish List</UncontrolledTooltip>
                </span> :
                <span>
                    <i className="fa fa-heart fa-2x text-danger link"
                    id={'Tooltip-' + data.reference_code}
                    onClick={() => this.props.addToWishList(data, 'cart')}></i>
                    <UncontrolledTooltip placement="top" target={'Tooltip-' + data.reference_code}>Add to Wish List</UncontrolledTooltip>
                </span> : ''} */
                }
            </div>
        )
    }

    _renderChildPrice(data){
        return(
            <div></div>
        )
    }

    _renderProductName(data) {
        const { settings } = this.props;
        const wikiUrl = process.env.WIKI_URL
        const showIsExpire = !settings.offline_mode && data.product && data.product.is_expire;
        const message = (data) => {
            const isAllergic = data.is_allergic;
            const isExpire = showIsExpire && data.product && data.product.is_expire ? data.product.is_expire : false;
            if (isAllergic && !isExpire) return data.allergic_message;
            if (isExpire && !isAllergic) return data.product && data.product.expire_date ? `EXP: ${data.product.expire_date}` : `EXP: ไม่ระบุวันหมดอายุ`;
            return `${data.allergic_message ? `${data.allergic_message},` : ''} ${data.product && data.product.expire_date ? `EXP: ${data.product.expire_date}` : `EXP: ไม่ระบุวันหมดอายุ`}`;
        }
        return (
            <span>
                {!data.product ? data.name :
                <span>
                    {!data.product_set_type && data.product.has_wiki ?
                    <a href={`${wikiUrl}/products/${data.product.reference_code}`}
                    target="_blank"
                    title="คลิกเพื่อดูข้อมูลยา">{data.product.reference_code} - {data.product.name}</a> :
                    `${data.product.reference_code} - ${data.product.name}`} {(data.is_allergic || showIsExpire) &&
                    <span>
                        <i className="fa fa-question-circle fa-lg" id={'Tooltip-' + data.product.id}></i>
                        <UncontrolledTooltip placement="right" target={'Tooltip-' + data.product.id}>{message(data)}</UncontrolledTooltip>
                    </span>}
                </span>}
                {
                    (data.product_set_type || data.product) ?
                        this._renderRemark(data.product)
                    :
                        this._renderRemark(data)
                }
            </span>
        )
    }

    _renderRemark(data){
        if( !data.configuration){
            return;
        }

        if(data.configuration.sales_note.trim() !== ""){
            return(
                <span className="ml-1">
                    <i className="fa fa-lg fa-info-circle" id={'tooltip-remark-' + data.id}></i>
                    <UncontrolledTooltip placement="top" target={'tooltip-remark-' + data.id}>
                        {data.configuration.sales_note}<br/>
                    </UncontrolledTooltip>
                </span>
            )
        }else{
            return
        }
    }

    _editWithShortcut(){
        const { editCartDetail, cart } = this.props;
        const detail = cart.details[cart.current_index];

        if(typeof(detail) !== 'undefined'){
            editCartDetail(detail);
        }
    }
    _deleteWithShortcut(){
        const { removeToCart, cart } = this.props;
        const detail = cart.details[cart.current_index];

        if(typeof(detail) !== 'undefined'){
            removeToCart(detail);
        }
    }
    _printDrugLabelWithShortcut(){

        const { printDrugLabel, setDrugLabelValue, cart } = this.props;
        const detail = cart.details[cart.current_index];

        if(typeof(detail) !== 'undefined'){
            setTimeout(() => { printDrugLabel(detail.product); })
        }
    }

    _switchLangauge(language){
        let headers = [this.props.getTableHeader(`${language.number}`,undefined,'index')
        // ,this.props.getTableHeader(`${language.reference_code}`, 'product.reference_code', 'text')
        ,this.props.getTableHeader(`${language.name}`,undefined,'custom', 4,this._renderProductName)
        ,this.props.getTableHeader(`${language.price}`,'price_per_unit','currency')
        ,this.props.getTableHeader(`${language.quantity}`,'sales_quantity','num')
        ,this.props.getTableHeader(`${language.unit}`,'unit.name','text')
        ,this.props.getTableHeader(`${language.total}`,'net_amount','currency')
        ,this.props.getTableHeader(`${language.manage}`,undefined,'custom', 3,this._renderCell)];

        let childHeaders = [this.props.getTableHeader(`${language.number}`,undefined,'index')
        // ,this.props.getTableHeader(`${language.reference_code}`, 'product.reference_code', 'text')
        ,this.props.getTableHeader(`${language.name}`,undefined,'custom', 4,this._renderProductName)
        ,this.props.getTableHeader(`${language.price}`,undefined,'custom', 1, this._renderChildPrice)
        ,this.props.getTableHeader(`${language.quantity}`,'quantity','num')
        ,this.props.getTableHeader(`${language.unit}`,'unit.name','text')
        ,this.props.getTableHeader(`${language.total}`,'net_amount','num')
        ,this.props.getTableHeader(`${language.manage}`,undefined,'custom', 3,this._renderChildCell)];

        this.setState({ headers: headers ,childHeaders : childHeaders});
    }

    render(){
        const closePlatformMessage = process.env.CLOSE_PLATFORM;
        const updatePlatform = process.env.UPDATE_PLATFORM;
        const { cart, reference_code, language, notiRefresh, closeNotiRefreshModal, submitNotiRefreshModal} = this.props;
        return(
            <CartShortcuts
                ref='cartMainDiv'
                upIndex={ this.props.upIndexCart }
                downIndex={ this.props.downIndexCart }
                editIndex={ this._editWithShortcut }
                printIndex={ this._printDrugLabelWithShortcut }
                delIndex={ this._deleteWithShortcut }
            >
                <div className="cart-product-container flex-grow-1"  >
                    <div className="product-search-result-header">
                        <div className="float-left">
                            {reference_code ? <h2>{language.list} : {reference_code}</h2> : <h2>{language.list} : SRxxxxx-xxxxx</h2>}
                        </div>
                        <div className="float-right">{ cart.present_quantity > 1 && <h2 className='text-danger'>{ cart.present_quantity }</h2> }</div>
                        <div className="clearfix"></div>
                    </div>
                    <PrescriptionCartInfo />
                    <div className="product-search-result-body no-padding">
                        <ACTable ref='acTable'
                        datas={ this.props.cart.details }
                        headers={ this.state.headers }
                        notFoundTxt={language.not_found_product}
                        currentRow={ cart.current_index }
                        childField="products"
                        childHeaders={ this.state.childHeaders }
                        onClickRow={ (index) => { this.props.setCartValue('current_index',index) } }
                        isOfflineMode={this.props.settings.offline_mode}
                        ></ACTable>
                    </div>
                    <PrintDrugLabel
                        showPrintDrugLabel={this.props.showPrintDrugLabel}
                        closePrintDrugLabel={this.props.closePrintDrugLabel}
                        language={this.props.language}
                        settings={this.props.settings}
                        employee={this.props.employee}
                        setDrugLabelValue={this.props.setDrugLabelValue}
                        switchLanguage={this.props.switchLanguage}
                        drugLabel={this.props.drugLabel}
                        selectTimeToEat={this.props.selectTimeToEat}
                        submitPrintDrungLabel={this.props.submitPrintDrungLabel}
                        resetPrintDrugLabel={this.props.resetPrintDrugLabel}
                        focus={this.props.printDrungLabelFocus}
                        cart={this.props.cart}
                        customer={this.props.customer}
                        prescription={this.props.prescription}
                    />
                    <NotiRefreshModal
                        show={notiRefresh.showModal}
                        onCancel={closeNotiRefreshModal}
                        onSubmit={submitNotiRefreshModal}
                        type={notiRefresh.type}
                        code={notiRefresh.code}
                        language={language}
                    />
                </div>
                { 
                    closePlatformMessage === 'WILL' 
                    ? <NotiWillClosePlatform /> 
                    : <></> 
                }
                { 
                    updatePlatform && updatePlatform !== '' ? <NotiUpdatePlatform /> : <></> 
                }
            </CartShortcuts>

        )
    }

    componentDidUpdate(prevProps){
        const { cartFocus: focus, setCartValue, language, cart, triggerCheckout, settings:{uuid}} = this.props;
        const { cartFocus: wasFocus, language: wasLanguage, cart: wasCart} = prevProps;

        if(cart.total_price != wasCart.total_price && uuid != null){
            triggerCheckout();
        }

        if (cart.coupon && wasCart.net_amount !== cart.net_amount) {
            this.props.checkCouponWhenUpdatedCart();
        }

        if(!wasFocus && focus){
            ReactDOM.findDOMNode(this.refs.cartMainDiv).focus();
            setCartValue('current_index', 0);
        }

        if(language !== wasLanguage){

            this._switchLangauge(language);

        }
    }

    componentDidMount(){

        const { onBlurCart, language } = this.props;

        const cartDiv = ReactDOM.findDOMNode(this.refs.cartMainDiv);
        cartDiv.addEventListener('blur',() => {
            onBlurCart()
        })

        this._switchLangauge(language);
    }

}

const mapStateToProps = state => {
    return {
        cart: {...state.cart},
        reference_code: state.cart.reference_code,
        language : state.language,
        showPrintDrugLabel: state.modal.showPrintDrugLabel,
        settings: state.settings,
        employee: state.employee,
        drugLabel: state.drugLabel,
        cartFocus: state.focus.cart,
        printDrungLabelFocus: state.focus.printDrungLabel,
        notiRefresh: state.modal.notiRefresh,
        prescription: state.prescription.data,
        customer: state.customer
    }
}

export default connect(mapStateToProps, { getTableHeader
    , printDrugLabel
    , removeToCart
    , editCartDetail
    , closePrintDrugLabel
    , setDrugLabelValue
    , selectTimeToEat
    , upIndexCart
    , downIndexCart
    , setCartValue
    , onBlurCart
    , submitPrintDrungLabel
    , resetPrintDrugLabel
    , addToWishList
    , removeWishList
    , checkCouponWhenUpdatedCart
    , triggerCheckout
    , closeNotiRefreshModal
    , switchLanguage
    , submitNotiRefreshModal })(Cart)

