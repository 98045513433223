import React from 'react'
import { connect } from 'react-redux'
import { Row, Col, FormGroup, Label, Input } from 'reactstrap'
import { searchProducts
    , openPickupPrescriptionModalWithQrCode
    , clearProducts
    , selectProduct
    , addToCart
    , setDetailValue
    , initialDetail
    , addDummy
    , setCartValue
    , isAble
    , checkOut
    , onBlurSearchTxt
    , onBlurAmountProduct
    , closeAddStockSet
    , addToWishList
    , removeWishList
    , notiUnitProduct
    , submitAddRetailUnit
    , cancelAddRetailUnit
    , setFindAllProducts
    , setInventoryValue
    , addInventory
    , updateProductPaginate
    , productsTypeahead
    , rejectExpireProduct
    , productErrorModal
    , openProductErrorModal
    , closeProductErrorModal
} from 'Redux/actions'

import SearchProductForm from 'Components/Form/SearchProductForm';
import SearchProductModal from 'Components/Modal/SearchProductModal';
import AddToCartModal from 'Components/Modal/AddToCartModal';
import AddStockSetModal from 'Components/Modal/AddStockSetModal';
import AddRetailUnitModal from 'Components/Modal/AddRetailUnitModal';
import ProductErrorModal from 'Components/Modal/ProductErrorModal';
class Search extends React.Component{

    render(){
        const { settings } = this.props;
        return(
            <div className="search-container">
                <Row>
                    <Col xs={settings.offline_mode ? 12 : 10}>
                        <SearchProductForm searchProducts={this.props.searchProducts}
                        openPickupPrescriptionModalWithQrCode={this.props.openPickupPrescriptionModalWithQrCode}
                        focus={this.props.focus.searchProduct} 
                        setCartValue={this.props.setCartValue}
                        cart={this.props.cart}
                        checkOut={this.props.checkOut}
                        onBlur={this.props.onBlurSearchTxt}
                        language={this.props.language}
                        productsTypeahead={this.props.productsTypeahead} 
                        />
                    </Col>
                    {!settings.offline_mode && <Col xs="2">
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    onChange={(e) => this.props.setFindAllProducts(e.target.checked)}
                                    checked={this.props.search.findAllProducts}
                                /> {this.props.language.search_all_products}
                            </Label>
                        </FormGroup>
                    </Col>}
                </Row>

                <SearchProductModal products={this.props.search.products} 
                showSearchProducts={this.props.modal.showSearchProducts} 
                query={this.props.search.product_query}
                clearProducts={this.props.clearProducts} 
                selectProduct={this.props.selectProduct}
                focus={this.props.focus.searchProductModal}
                addDummy={this.props.addDummy}
                language={this.props.language}
                addToWishList={this.props.addToWishList}
                removeWishList={this.props.removeWishList}
                settings={this.props.settings}
                employee={this.props.employee}
                notiUnitProduct={this.props.notiUnitProduct}
                setInventoryValue={this.props.setInventoryValue}
                search={this.props.search}
                updateProductPaginate={this.props.updateProductPaginate}
                openProductErrorModal={this.props.openProductErrorModal}
                />
        
                <AddToCartModal showModal={this.props.modal.showAddToCart} 
                addToCart={this.props.addToCart} 
                focus={this.props.focus.amountProduct}
                referenceCode={ this.props.reference_code }
                setDetailValue={this.props.setDetailValue}
                initialDetail={this.props.initialDetail}
                detail={ this.props.detail }
                employee={ this.props.employee }
                isAble={ this.props.isAble }
                onBlur={ this.props.onBlurAmountProduct }
                language={this.props.language} 
                customer={this.props.customer}
                addToWishList={this.props.addToWishList}
                removeWishList={this.props.removeWishList}
                settings={this.props.settings}
                notiUnitProduct={this.props.notiUnitProduct} 
                query={this.props.search.product_query}
                inventory={this.props.inventory}
                setInventoryValue={this.props.setInventoryValue}
                addInventory={this.props.addInventory}
                prescription={this.props.prescription} />

                <AddStockSetModal addStockSet={this.props.modal.addStockSet}
                closeAddStockSet={this.props.closeAddStockSet}
                language={this.props.language}
                products={this.props.product.products}
                addDummy={this.props.addDummy} />

                <ProductErrorModal 
                    productErrorModal={this.props.search.productErrorModal}
                    openProductErrorModal={this.props.openProductErrorModal}
                    productErrorModalMessages={this.props.search.productErrorModalMessages}
                    closeProductErrorModal={this.props.closeProductErrorModal}
                    language={this.props.language}
                />

                <AddRetailUnitModal
                    onCancel={this.props.cancelAddRetailUnit}
                    onSubmit={this.props.submitAddRetailUnit}
                    product={this.props.product}
                    show={this.props.modal.addRetailUnit}
                    language={this.props.language}
                    loader={this.props.loader.addRetailUnit}
                    query={this.props.search.product_query}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    
    return {
        search: {...state.search},
        modal: {...state.modal},
        focus: state.focus,
        detail: {...state.detail},
        reference_code: state.cart.reference_code,
        employee: {...state.employee.employee},
        cart: state.cart,
        language : state.language,
        customer: state.customer,
        settings: state.settings,
        product: state.product,
        loader: state.loader,
        inventory: state.inventory,
        prescription: state.prescription,
    }
}

export default connect(mapStateToProps
    , { searchProducts
    , openPickupPrescriptionModalWithQrCode
    , clearProducts
    , selectProduct
    , addToCart
    , setDetailValue
    , initialDetail
    , addDummy
    , setCartValue
    , isAble
    , checkOut
    , onBlurSearchTxt
    , onBlurAmountProduct
    , closeAddStockSet
    , addToWishList
    , removeWishList
    , notiUnitProduct
    , submitAddRetailUnit
    , cancelAddRetailUnit
    , setFindAllProducts
    , setInventoryValue
    , addInventory
    , updateProductPaginate 
    , productsTypeahead
    , rejectExpireProduct
    , openProductErrorModal
    , closeProductErrorModal
}
)(Search)
