export const OPEN_ADD_TO_CART = 'OPEN_ADD_TO_CART';
export const CLOSE_ADD_TO_CART = 'CLOSE_ADD_TO_CART';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_TO_CART = 'REMOVE_TO_CART';
export const INITIAL_CART_DETAIL = 'INITIAL_CART_DETAIL';
export const EDIT_CART_DETAIL = 'EDIT_CART_DETAIL';
export const EDIT_TO_CART = 'EDIT_TO_CART';
export const SET_CART_VALUE ='SET_CART_VALUE';
export const EDIT_PRODUCT_SET = 'EDIT_PRODUCT_SET';
export const CHANGE_STATUS_CART = 'CHANGE_STATUS_CART';
export const ERROR_ADD_TO_CART = 'ERROR_ADD_TO_CART';
export const FOCUS_CART = 'FOCUS_CART';
export const UP_INDEX_CART = 'UP_INDEX_CART';
export const DOWN_INDEX_CART = 'DOWN_INDEX_CART';
export const ON_BLUR_CART = 'ON_BLUR_CART';
export const EDIT_TO_CART_AND_PLUS = 'EDIT_TO_CART_AND_PLUS';
export const FEE_PAYMENT_CHANGE = 'FEE_PAYMENT_CHANGE';
export const CHANGE_UNIT_IN_CART = 'CHANGE_UNIT_IN_CART';
export const SET_BODC12_DATA = 'SET_BODC12_DATA';
export const CLEAR_BODC12_DATA = 'CLEAR_BODC12_DATA';

