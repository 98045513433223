import React from 'react';

export const DateTime = {
    startDate: process.env.CLOSE_PLATFORM_START_DATE || '1970-01-01',
    endDate: process.env.CLOSE_PLATFORM_END_DATE || '1970-01-01',
    startTime: process.env.CLOSE_PLATFORM_START_TIME || '00:00',
    endTime: process.env.CLOSE_PLATFORM_END_TIME || '00:00',
    startUpdateDate: process.env.UPDATE_PLATFORM_DATE || '1970-01-01',
}

export function dateFormat(dateInput) {
    // Handle invalid input
    if (!dateInput || typeof dateInput !== 'string') {
        return <>Invalid Date</>;
    }

    // Match the pattern YYYY-MM-DD
    let datePart = dateInput.match(/\d+/g);
    if (datePart && datePart.length >= 3) {
        let year = datePart[0];
        let month = datePart[1];
        let day = datePart[2];

        return <>{ day + '/' + month + '/' + year }</>;
    }

    return <>Invalid Date</>;
}

export function timeFormat(timeInput) {
    if (!timeInput || typeof timeInput !== 'string') {
        return <>Invalid Time</>;
    }

    let timePart = timeInput.match(/\d+/g);
    if (timePart && timePart.length >= 2) {
        let hour = timePart[0];
        let minute = timePart[1];

        return <>{ hour + ':' + minute }</>;
    }

    return <>Invalid Time</>;
}
