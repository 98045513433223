import { RECEIVE_SETTINGS
    , REQUEST_SETTINGS
    , EMPTY_SETTINGS
    , SWITCH_LANGUAGE
    , OPEN_PROFILE_MODAL
    , CLOSE_PROFILE_MODAL
    , NOTI_SYNC_DATA
    , SETTING_QUICK_POS
    , SET_PRINTERS
    , SHOW_NOTI_WRONG_TIME
    , SETTING_NOTI_SOUND_PLAY
    , SETTING_NOTI_SOUND_LOOP
    , OPEN_MEDAD_MODAL
} from '../actionTypes'

import db from '../../utils/db'
import { push } from 'react-router-redux'
import _ from 'lodash'
import moment from 'moment'
import axiosHelper from '../middleware/axios';
import {
    selectProfilePanel,
    setCurrentPage,
    isEnableServices,
    setServiceDeliveryCancellationInterval,
    listenServiceDeliveryCancellationOnSocket,
    listenSyncHatoNotificationOnSocket
} from 'Redux/actions';
import {
    listenPrescriptionInboxOnSocket,
    setPrescriptionInboxCountInterval,
} from 'Redux/actions/prescription';
import { reSyncOnline } from '../actions/syncSaleReceipt'
import { notify } from 'reapop'
import { dispatchCheckout } from './checkout'

let helper = new axiosHelper();

export const getSettings = settings => dispatch => {
    const { company, branch, token } = settings

    return helper.get(`/companies/${company.slug}/branches/${branch.slug}/pos`, {}, {
        Authorization : `Bearer ${token}`,
        Accept : 'application/json'
    }, 3).then(res => {
        const company = res.company
        const branch = res.branch
        let tax_percentage = 7
        let tax_types = 'vat_included'
        let tax_show = true
        let printBill = null
        let printAddress = null
        let credit_include = null
        let fee = null
        let enableReferral = null
        let lastSalesReceipt = []

        company.configurations.map(configuration => {
            switch (configuration.slug.trim()) {
                case 'TAX_PERCENTAGE': tax_percentage = configuration.value
                break
                case 'POS_TAX_TYPES': tax_types = configuration.value
                break
                case 'SALES_RECEIPT_TAX_SHOW': tax_show = configuration.value
                break
                case 'POS_PRINT_TYPE': printBill = configuration.value
                break
                case 'POS_PRINT_ADDRESS': printAddress = configuration.value
                break
                case 'CREDIT_FEE': credit_include = configuration.value
                break
                case 'CREDIT_FEE_PERCENT': credit_include == 'no_credit_fee' ?
                fee = 0 : fee = configuration.value
                break
                case 'ENABLE_REFERRAL':
                    enableReferral = !!+configuration.value;
                    break

            }
        })

        company.configurations.map(configuration => {
            configuration.slug.trim().includes('SALE_RECEIPT_ROW') &&
            lastSalesReceipt.push(JSON.parse(configuration.value))
        })

        const showOnBill = {
            company: true,
            branch: true,
            address: true,
            phone: true,
        }
        
        branch.branch_setting.map(setting => {
            switch (setting.branch_setting_slug.trim()) {
                case 'SHOW_COMPANY_ON_BILL':
                    showOnBill.company = setting.branch_setting_value == 'true' ? true : false
                break
                case 'SHOW_BRANCH_ON_BILL':
                    showOnBill.branch = setting.branch_setting_value == 'true' ? true : false
                break
                case 'SHOW_ADDRESS_ON_BILL':
                    showOnBill.address = setting.branch_setting_value == 'true' ? true : false
                break
                case 'SHOW_PHONE_ON_BILL':
                    showOnBill.phone = setting.branch_setting_value == 'true' ? true : false
                break
            }
        });

        branch.showOnBill = showOnBill;
        delete branch.branch_setting;

        branch.telepharmacy = {
            voice: Boolean(branch.enable_telepharmacy_voice),
            chat: Boolean(branch.enable_telepharmacy_chat),
        }

        const newSettings = {
            token: token,
            me: res.me,
            company: {
                id: company.id,
                slug: company.slug,
                name: company.name,
                tax_id: company.tax_id,
                fee,
                credit_include,
                lastSalesReceipt,
                enableReferral,
                is_medad:company.is_medad,
            },
            branch,
            payment: res.payment,
            tax_percentage: tax_percentage,
            tax_types: tax_types,
            tax_show: tax_show,
            printBill: printBill,
            printAddress: printAddress,
            printers: res.printer,
            offline_mode: false,
            lastSync: settings.lastSync,
            is_medad: res.is_medad,
        }

        const match = _.isEqual(settings, newSettings)

        if (match) {
            dispatch({ type: RECEIVE_SETTINGS, payload: settings })
        } else {
            dispatch({ type: RECEIVE_SETTINGS, payload: newSettings })
            db.table('settings').put(newSettings, 0)
        }

        return { settingFailed: false }
    }).catch((err) => {
        console.error(err);

        settings.branch.telepharmacy = {
            voice: false,
            chat: false,
        }

        dispatch({ type: RECEIVE_SETTINGS, payload: settings })
        return { settingFailed: true }
    })
}

export const getOfflineData = settings => dispatch => {
    
    Promise.all([
        getProducts(settings),
        getEmployees(settings),
        getCustomers(settings),
        dispatch(getPrinters(settings))
    ]).then(res => res && db.table('settings').update(0, { lastSync: moment().format('YYYY-MM-DD') }))
}

const getOnlineSettings = settings => dispatch => {

    dispatch(getServerTime())
    dispatch(getSettings(settings)).then(() => {
        if (!settings.lastSync || settings.lastSync < moment().format('YYYY-MM-DD')) {
            dispatch(getOfflineData(settings))
        }

        if (settings.branch.enable_prescription) {
            // Prescription inbox listener
            dispatch(setPrescriptionInboxCountInterval());
            dispatch(listenPrescriptionInboxOnSocket());
        }

        if (isEnableServices(settings)) {
            dispatch(setServiceDeliveryCancellationInterval());
            dispatch(listenServiceDeliveryCancellationOnSocket());
        }
        dispatch(listenSyncHatoNotificationOnSocket());
    })
}

const getOfflineSettings = settings => dispatch => {
    dispatch({ type: RECEIVE_SETTINGS, payload: settings })

    if (settings.lastSync < moment().format('YYYY-MM-DD')) {
        dispatch({ type: NOTI_SYNC_DATA })
    }
}

export const loadSettings = () => dispatch => {
    dispatch({ type: REQUEST_SETTINGS })

    return db.table('settings').count(count => {
        if (count > 0) {

            db.table('settings').get(0, (settings => {
                localStorage.setItem('permission', true)
                settings.offline_mode ?
                dispatch(getOfflineSettings(settings)) :
                dispatch(getOnlineSettings(settings))
            }))
        } else {
            localStorage.removeItem('permission')
            dispatch(push('/login'))
            dispatch({ type: EMPTY_SETTINGS })
        }
    })
}

export const switchLanguage = (language) => dispatch => {
    localStorage.setItem('language', language)
    return dispatch({ type: SWITCH_LANGUAGE, payload: language })
}

export const changeSettings = () => dispatch => {
    return dispatch(push('/login'))
}

export const backToPos = () => dispatch => {
    dispatch(setCurrentPage(1));
    return dispatch(push('/'))
}

export const openProfileModal = () => (dispatch, getState) =>{
    const { offline_mode } = getState().settings

    dispatch({ type: OPEN_PROFILE_MODAL })
    dispatch(selectProfilePanel(offline_mode ? 1 : 0));
}

export const closeProfileModal = () => dispatch => {
    return dispatch({ type: CLOSE_PROFILE_MODAL })
}

const setMomentNow = (serverTime) => {
    const diff = serverTime - Date.now()
    moment.now = () => {
        return Date.now() + diff; 
    }
}

export const getServerTime = () => dispatch => {
    helper.get('/time', {}, {}, 3).then(res => {
        const serverTime = res.now;
        const diffHours = moment(Date.now()).diff(moment(serverTime), 'hours', true);

        if (Math.abs(diffHours) > 0.9) {
            return dispatch({ type: SHOW_NOTI_WRONG_TIME, payload: serverTime });
        }

        // setMomentNow(res.now)
    })
}

export const setOfflineMode = value => dispatch => {
    db.table('settings').update(0, { offline_mode: value }).then(() => {
        dispatch(loadSettings())
        if (!value) dispatch(reSyncOnline())
    });
}

export const toggleOffline = () => (dispatch, getState) => {
    const { offline_mode, status_online } = getState().settings

    if (offline_mode && !status_online) {
        dispatch(notify({
            id: 'offlineMode',
            message: 'หากต้องการปิดโหมดออฟไลน์ กรุณาเชื่อมต่ออินเตอร์เน็ต หรือตรวจสอบให้แน่ใจว่าคุณได้เชื่อมต่อกับอินเตอร์เน็ต',
            status: 'info',
            dismissible: true,
            dismissAfter: 5000
        }))
        return;
    }

    offline_mode ? dispatch(setOfflineMode(false)) : dispatch(setOfflineMode(true))
}

const getProductStats = data => {
    const { company, token } = data
    return helper.get(`/companies/${company.slug}/products/statistics`, {}, {
        'Authorization': `Bearer ${token}`
    }, 3)
}

const reqProducts = (page, data) => {
    const { company, branch, token, fields, limit } = data
    return new Promise(resolve => {
        return helper.get(`/companies/${company.slug}/products?branch_id=${branch.id}&fields=${fields}&limit=${limit}&form_page=pos&page=${page}`, {}, {
            'Authorization': `Bearer ${token}`
        }, 3).then(res => resolve(res)).catch(err => {
            console.error(err);
            resolve([]);
        })
    });
}

const getProducts = async settings => {
    const { company, branch, token } = settings
    const fields = 'price,formula,ingredients,remaining_quantity,unit,cost,bureauOfDrugControls,wishlist';
    const limit = 1000
    let productTotalActivated = 0
    let products = []

    const stats = await getProductStats({ company, token })
    if (stats && stats.activated) productTotalActivated = Number(stats.activated)

    const lastPage = Math.ceil(productTotalActivated / limit) || 1

    for (let i = 0; i < lastPage; i++) {
        const page = i + 1;
        const res = await reqProducts(page, { company, branch, token, fields, limit })
        if (res.products && res.products.length) products = [...products, ...res.products]
    }

    const newProducts = products.map(product => {
        if (Boolean(product.is_product_set) && product.products_sets_id) {
            product = { ...product, id: product.reference_code };
        }
        return product;
    });

    db.table('products').toArray().then(_products => {
        !_.isEqual(newProducts, _products) && db.table('products').clear().then(() => {
            db.table('products').bulkAdd(newProducts)
        })
    });

    return newProducts
}

const getEmployees = settings => {
    const { company, branch, token } = settings

    return helper.get(`/companies/${company.slug}/branches/${branch.slug}/pos/employee`, {}, {
        'Authorization': `Bearer ${token}`
    }, 3).then(res => {
        const data = res.data.map(employee => {
            if (!employee.reference_code) {
                return { ...employee, reference_code: employee.id };
            }
            return employee;
        })

        db.table('employees').toArray().then(employees => {
            !_.isEqual(data, employees) && db.table('employees').clear().then(() => {
                db.table('employees').bulkAdd(data)
            })
        })

        return res
    })
}

const getCustomers = settings => {
    const { company, branch, token } = settings

    return helper.get(`/companies/${company.slug}/branches/${branch.slug}/pos/customers`, {}, {
        'Authorization': `Bearer ${token}`
    }, 3).then(res => {
        db.table('customers').toArray().then(customers => {
            !_.isEqual(res.data, customers) && db.table('customers').clear().then(() => {
                db.table('customers').bulkAdd(res.data)
            })
        })

        return res
    })
}

const getPrinters = settings => (dispatch) => {

    const { company, branch, token } = settings

    return helper.get(`/companies/${company.slug}/branches/${branch.slug}/pos/printer`, {}, {
        'Authorization': `Bearer ${token}`
    }, 3).then(res => {
        dispatch({
            type: SET_PRINTERS,
            payload: res.printer
        });
        db.table('settings').get(0, settings => {
            !_.isEqual(res.printer, settings.printers) && db.table('settings').update(0, { printers: res.printer })
        })

        return res
    })
}

export const getQuickPos = () => {
    const value = localStorage.getItem('quickPos');
    return value === 'true';
}

export const setNotiEpSoundLoop = value => (dispatch) => {
    dispatch({ type: SETTING_NOTI_SOUND_LOOP, payload: value })
}

export const setNotiEpSoundPlay = value => (dispatch) => {
    dispatch({ type: SETTING_NOTI_SOUND_PLAY, payload: value })
}

export const closeMedAdherenceModal = () => dispatch => {
    dispatch({
        type: OPEN_MEDAD_MODAL,
        payload: false,
    });

    return dispatch(dispatchCheckout());
}
export const submitMedAdherenceModal = () => dispatch => {
     dispatch({
        type: OPEN_MEDAD_MODAL,
        payload: false,
    });
    return dispatch(dispatchCheckout());
}