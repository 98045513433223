import React from 'react'
import { connect } from 'react-redux'
import { Button, Table } from 'reactstrap';
import {
    checkOut
    , closeCheckOut
    , calculateAmount
    , calculateChangeAmount
    , calculateNetAmount
    , inputDigit
    , inputDot
    , setCash
    , checkOutSubmit
    , closeCheckedOut
    , employeeLogout
    , setCartValue
    , printSaleReceipt
    , afterCloseCheckedOut
    , changePaymentType
    , onBlurCheckedout
    , closeDangerModal
    , submitDangerModal
    , setValueInDGMed
    , onCloseQRPayment
    , refreshQR
    , focusPaidAmount
    , onBlurPaidAmount
    , onSubmitQRResult
    , inquireQR
    , setLinePayValue
    , closeAlipayWechatModal
    , onOpenAlipayWechatModal
    , inquiryAlipayWechat
    , openCouponModal
    , removeCartCoupon
    , closeMedAdherenceModal
    , submitMedAdherenceModal
} from 'Redux/actions';
import { priceFormat } from 'Utilz/currency';

import CheckOutModal from 'Components/Modal/CheckOutModal';
import CheckedOutModal from 'Components/Modal/CheckedOutModal';
import SaleReceiptForm from 'Components/Form/SaleReceiptForm';
import DangerMedModal from 'Components/Modal/DangerMedModal';
import QRResultModal from 'Components/Modal/QRResultModal';
import QRPaymentModal from 'Components/Modal/QRPaymentModal';
import AlipayWechatPaymentModal from 'Components/Modal/AlipayWechatPaymentModal';
import CouponModel from 'Components/Modal/CouponModel';
import PrintWrapper from 'Components/Page/Print';
import MedAdherenceModal from 'Components/Modal/MedAdherenceModal';

class FinalPrice extends React.Component {
    render() {
        const { cart
                , prescription
                , language
                , printSaleReceipt
                , saleReceipt
                , settings
                , employee
                , loader
                , changePaymentType
                , modal
                , qr 
                , focus
                , onCloseQRPayment
                , refreshQR
                , onSubmitQRResult
                , inquireQR 
                , closeAlipayWechatModal
                , onOpenAlipayWechatModal
                , inquiryAlipayWechat
                , openCouponModal
                , removeCartCoupon
                , customer
                , is_med_ad_active
        } = this.props;
        
        return (
            <div className="final-price">
                <Table hover>
                    <tbody>
                        <tr>
                            <td>{language.price}</td>
                            <td>
                                <div className="price-tag">{priceFormat(cart.net_amount, true)}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {language.discount}
                                {' | '}
                                {cart.coupon ? (
                                    <>
                                        <a className="text-primary" style={{ cursor: 'pointer' }} onClick={openCouponModal}>
                                            <strong>{cart.coupon.code}</strong>
                                        </a>
                                        {' '}
                                        <Button color="danger" size="sm" style={{ width: 70, padding: 0 }} onClick={removeCartCoupon}>
                                            Remove
                                        </Button>
                                    </>
                                ) : (
                                    <Button 
                                        color="primary" 
                                        size="sm" 
                                        // style={{ width: 70, padding: 0 }} 
                                        style={{
                                            width: customer.id && (customer.is_hato_costomers === true) ? 160 : 70,
                                            padding: 0,
                                          }}
                                        onClick={openCouponModal}>
                                        {
                                            customer.id && (customer.is_hato_costomers === true)
                                                ? `${language.coupons} (${customer.hato_costomers_coupon}) / ${customer.hato_costomers_points} ${language.points}`
                                                : 'Coupon'
                                        }
                                    </Button>
                                )}
                            </td>
                            <td>
                                <div className="price-tag">{priceFormat(cart.total_discount_amount, true)}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span>{language.price}</span>{' '}
                                <span>({language.after_discount})</span>
                            </td>
                            <td>
                                <div className="price-tag">{priceFormat(cart.price, true)}</div>
                            </td>
                        </tr>
                        {
                            cart.tax_types !== 'no_vat' &&
                            <tr>
                                <td>
                                    <span>{language.tax}</span>{' '}
                                    <span>{ cart.tax_percentage }%</span>
                                </td>
                                <td>
                                    <div className="price-tag">{priceFormat(cart.tax_amount, true)}</div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
                <div className="total-price">
                    <div className="price-tag">
                        <span className="total-title">{language.total_price}</span>
                        {priceFormat(cart.total_price, true)}
                    </div>
                </div>
                <div className="checkout-button-container">
                    <CheckOutModal
                        checkOut={this.props.checkOut}
                        showCheckOutModal={this.props.modal.showCheckOutModal}
                        onCreditChange={this.props.onCreditChange}
                        closeCheckOut={this.props.closeCheckOut}
                        language={this.props.language}
                        cart={this.props.cart}
                        prescription={this.props.prescription}
                        setCartValue={this.props.setCartValue}
                        focus={this.props.focus.paidAmountInput}
                        settings={this.props.settings}
                        calculateAmount={this.props.calculateAmount}
                        calculateChangeAmount={this.props.calculateChangeAmount}
                        calculateNetAmount={this.props.calculateNetAmount}
                        inputDigit={this.props.inputDigit}
                        inputDot={this.props.inputDot}
                        setCash={this.props.setCash}
                        checkOutSubmit={this.props.checkOutSubmit}
                        print={printSaleReceipt}
                        loader={loader.checkout}
                        changePaymentType={changePaymentType}
                        focusPaidAmount={this.props.focusPaidAmount}
                        onBlurPaidAmount={this.props.onBlurPaidAmount}
                        linePay={this.props.linePay}
                        setLinePayValue={this.props.setLinePayValue}
                    />
                    <DangerMedModal
                        language={this.props.language}
                        dg_med={this.props.dangerMed.dg_med}
                        onSubmit={this.props.submitDangerModal}
                        onClose={this.props.closeDangerModal}
                        show={this.props.modal.dgMedModal}
                        focus={this.props.focus.dgMedModal}
                        onChange={(txt) => { this.props.setCartValue('danger_note',txt)}}
                        value={cart.danger_note}
                    />
                    <CheckedOutModal
                        showCheckedOutModal={this.props.modal.showCheckedOutModal}
                        language={this.props.language}
                        closeCheckedOut={this.props.closeCheckedOut}
                        saleReceipt={this.props.saleReceipt}
                        employeeLogout={this.props.employeeLogout}
                        print={ printSaleReceipt }
                        focus={this.props.focus.checkedOutModal}
                        onClose={this.props.afterCloseCheckedOut}
                        onBlur={this.props.onBlurCheckedout}
                    />
                    <QRResultModal
                        title={'Thai QR'}
                        show={ modal.qrResultModal}
                        onClose={ onSubmitQRResult}
                        onSubmit={ onSubmitQRResult}
                        focus={ focus.qrResultModal}
                        language= { language}
                        saleReceipt={ saleReceipt}
                        {...qr}
                    />
                    <QRPaymentModal
                        show={modal.qrPaymentModal}
                        language= { language}
                        focus={ focus.qrPaymentModal}
                        onClose={ onCloseQRPayment}
                        onRefresh={ refreshQR}
                        payment={ settings.payment}
                        cart={ cart}
                        onInquireQR={inquireQR}
                        loader={loader}
                        {...qr}
                    />
                    <AlipayWechatPaymentModal
                        show={modal.alipayWechat}
                        close={closeAlipayWechatModal}
                        language={language}
                        onOpen={onOpenAlipayWechatModal}
                        refresh={inquiryAlipayWechat}
                        payment_types={cart.payment_types}
                    />
                    <CouponModel
                        calculateAmount={this.props.calculateAmount}
                        setCartValue={this.props.setCartValue}
                    />
                    <MedAdherenceModal
                        language={this.props.language}
                        show={this.props.is_med_ad_active}
                        onSubmit={this.props.submitMedAdherenceModal}
                        onClose={this.props.closeMedAdherenceModal}
                        onChange={(txt_med_ad_phone_number) => { this.props.setCartValue('med_ad_phone_number',txt_med_ad_phone_number)}}
                        value={cart.med_ad_phone_number}
                    />
                </div>
                <SaleReceiptForm 
                saleReceipt={ saleReceipt }
                settings={ settings }
                employee={ employee }></SaleReceiptForm>
                <PrintWrapper
                    saleReceipt={this.props.saleReceipt}
                    employee={this.props.employee}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        cart: {...state.cart}
        , prescription : state.prescription
        , language : state.language
        , focus: {...state.focus}
        , settings: state.settings
        , saleReceipt: state.saleReceipt
        , employee: state.employee
        , loader: state.loader
        , modal: state.modal
        , dangerMed: state.dangerMed
        , qr: state.qr
        , linePay: state.linePay
        , customer: state.customer 
        , is_med_ad_active: state.settings.medAdActive 
    }
}

export default connect(mapStateToProps, { checkOut
    , closeCheckOut
    , calculateAmount
    , calculateChangeAmount
    , calculateNetAmount
    , inputDigit
    , inputDot
    , setCash
    , checkOutSubmit
    , closeCheckedOut
    , employeeLogout
    , setCartValue
    , printSaleReceipt
    , afterCloseCheckedOut
    , changePaymentType
    , onBlurCheckedout
    , closeDangerModal
    , submitDangerModal
    , setValueInDGMed
    , onCloseQRPayment
    , refreshQR
    , focusPaidAmount
    , onBlurPaidAmount
    , onSubmitQRResult
    , inquireQR
    , setLinePayValue
    , closeAlipayWechatModal
    , onOpenAlipayWechatModal
    , inquiryAlipayWechat
    , openCouponModal
    , removeCartCoupon
    , closeMedAdherenceModal
    , submitMedAdherenceModal
})(FinalPrice)
