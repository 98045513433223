import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import { Modal, ModalHeader, ModalBody, FormGroup, Col, Input, Button, Label, Row } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import SelectFromGroup from 'Components/Input/SelectFormGroup'
import iconCheckBlack from '../../images/check_black_24dp.svg';
import iconCircleBlack from '../../images/circle_black_24dp.svg';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import 'react-datepicker/dist/react-datepicker.css';

const dateFormate = 'DD/MM/YYYY';
const locales = {
    th: {
        date: 'วันที่',
        patient: 'ผู้ป่วย',
        pharmacist: 'เภสัชกรผู้จ่ายยา',
        morning: 'เช้า',
        noon: 'กลางวัน',
        evening: 'เย็น',
        before_bed: 'ก่อนนอน',
        when_symptoms_occur: 'เมื่อมีอาการ',
        times: 'ครั้ง',
        not_specify: 'ไม่ระบุ',
        before_30: 'ก่อนอาหาร 30 นาที',
        before_meal: 'ก่อนอาหาร',
        after_meal: 'หลังอาหาร',
        during: 'พร้อมอาหาร',
        internal_use: 'ใช้ภายใน',
        external_use: 'ใช้ภายนอก',
    },
    en: {
        date: 'Date',
        patient: 'Patient',
        pharmacist: 'Pharmacist',
        morning: 'Breakfast',
        noon: 'Noon',
        evening: 'Evening',
        before_bed: 'Before bed',
        when_symptoms_occur: 'When symptoms occur',
        times: 'times',
        not_specify: 'Not specified',
        before_30: 'Before meal 30 minutes',
        before_meal: 'Before meal',
        after_meal: 'After meal',
        during: 'During meal',
        internal_use: 'ใช้ภายใน',
        external_use: 'External use'
    },
    cn: {
        date: '日期',
        patient: '病人',
        pharmacist: '药剂师',
        morning: '早饭',
        noon: '中饭',
        evening: '晚饭',
        before_bed: '睡觉前',
        when_symptoms_occur: '痛的时候服用',
        times: '次',
        not_specify: '未指定',
        before_30: '饭前30分钟',
        before_meal: '饭前',
        after_meal: '饭后',
        during: '饭中',
        internal_use: '内服',
        external_use: '外用药'
    }
}

const fontTitlePrintDetail = '11px';
const fontTitleSmallPrintDetail = '9.5px';
const fontSizeForPrintDetail = '11.5px';
const saveLabel = {
    disabled    : true,
    remark      : "(เฉพาะเภสัชกรเท่านั้น)",
    styleColor  : "#888"
};

const printIconCheckBlack = {
    display: "inline-block",
    verticalAlign: "middle",
    width: fontSizeForPrintDetail,
    height: fontSizeForPrintDetail,
}
const printIconCircleBlack = {
    display: "inline-block",
    verticalAlign: "middle",
    width: fontSizeForPrintDetail,
    height: fontSizeForPrintDetail,
}
const printGroup = {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: "2px"
}
const printGroupLg = {
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: "8px"
}
const printLabel = {
    display: "inline-block",
}
const printValue = {
    display: "inline-block",
    maxHeight: "40.5px",
    overflow: "visible",
    whiteSpace: "normal",
    wordBreak: "break-all"
}

class PrintDrugLabel extends React.Component {

    prevLang = createRef()

    constructor(props) {
        super(props)
        const {language} = props
        this._handleChangeInput = this._handleChangeInput.bind(this)
        this._handleClickCheckBox = this._handleClickCheckBox.bind(this)
        this._handleDisplayStore = this._handleDisplayStore.bind(this)
        this._handleSaveLable = this._handleSaveLable.bind(this)
        this._handleIncludeDrugInfo = this._handleIncludeDrugInfo.bind(this)
        this._handleChangeDatePicker = this._handleChangeDatePicker.bind(this)
        this._handleChangeExpDatePicker = this._handleChangeExpDatePicker.bind(this)
        this._onChangeLocal = this._onChangeLocal.bind(this)
        this._onChangeTakeTime = this._onChangeTakeTime.bind(this)
        this._handleChangeCheckbox = this._handleChangeCheckbox.bind(this)
        this.state = {
            disableReset: true,
            date: moment(),
            showPharmacist: true,
            language: 'th',
            defaultLang: 'th',
            locales: locales['th'],
            taken_times: [
                { id: 1, name: `${language.not_specify}`, value: 0 },
                { id: 2, name: `${language.before_meal}`, value: 1 },
                { id: 3, name: `${language.after_meal}`, value: 2 },
                { id: 4, name: `${language.external_use}`, value: 3 },
                { id: 5, name: `${language.internal_use}`, value: 4 },
                { id: 6, name: `${language.before_30}`, value: 5 },
                { id: 7, name: `${language.during}`, value: 6 },
            ]
        }   
    }

    exceptString(str) {
        return str.replace(/[|ิ|ี|ื|ึ|์|ิ|่|้|๊|๋|็|ู|ุ|ํ|ั|"]/g,'')
    }

    isNumber(name, value, limit) {
        let regex =/^[0-9,-\/]{1,4}$/g;
        if (limit == 3) {
            regex =/^[0-9,-\/]{1,3}$/g;
        }
        
        const nameEl = `textarea[name='${name}']`
        const test = regex.test(value)
        if (!test && value != "") {
            document.querySelector(nameEl).classList.add('is-overflow')
            this.props.drugLabel.is_over_limit.push(name)     
        } else {
            const filtered = this.props.drugLabel.is_over_limit.filter((item) => {
                return item != name
            })
            document.querySelector(nameEl).classList.remove('is-overflow') 
            this.props.setDrugLabelValue('is_over_limit', filtered)
        }
    }

    isOnlyNumber(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        if (key.length == 0) return;
        var regex = /^[0-9/\b]+$/;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }

    _handleChangeInput(e) {
        const { name, value } = e.target

        const limits = {
            patient_name: 25,
            drug_name: 30,
            dosage_instruction: 60,
            how_to_use: 60,
            unit: 4,
            unit_intake: 10,
            taken_symptom_occur: 26,
            symptom_occur: 60,
            note: 60,
            taken_other:3
        }

        if (typeof(limits[name]) != 'undefined') {
            const cleanString = this.exceptString(value)
            const nameEl = `input[name='${name}']`
            const nameElArea = `textarea[name='${name}']`
            if (cleanString.length > limits[name]) {
                if (document.querySelector(nameEl) != undefined) {
                    document.querySelector(nameEl).classList.add('is-overflow')
                }
                
                if (document.querySelector(nameElArea) != undefined) {
                    document.querySelector(nameElArea).classList.add('is-overflow')
                }
                this.props.drugLabel.is_over_limit.push(name)
                
            } else {
                const filtered = this.props.drugLabel.is_over_limit.filter((item) => {
                    return item != name
                })
                if(!filtered.includes(name)) {
                    if (document.querySelector(nameEl) != undefined) {
                        document.querySelector(nameEl).classList.remove('is-overflow')
                    }
                    if (document.querySelector(nameElArea) != undefined) {
                        document.querySelector(nameElArea).classList.remove('is-overflow')
                    }
                }

                this.props.setDrugLabelValue('is_over_limit', filtered)
            }
        }
        if (name === 'volume_intake') {
            this.isNumber('volume_intake', value, 4)
        }
        if (name === 'daily_frequency_intake') {
            this.isNumber('daily_frequency_intake', value, 3)
        }
        if (name === 'taken_other') {
            this.isNumber('taken_other', value, 3)
        }
        
        this.props.setDrugLabelValue(name, value)
        this.setState({disableReset: false})   
    }

    _onChangeLocal(e) {
        const { value } = e.target;
        this.setState({ language: value, locales: locales[value] });
        this.props.switchLanguage(value)
        this.setState({ taken_times: [
            { id: 1, name: `${locales[value].not_specify}`, value: 0 },
            { id: 2, name: `${locales[value].before_meal}`, value: 1 },
            { id: 3, name: `${locales[value].after_meal}`, value: 2 },
            { id: 4, name: `${locales[value].external_use}`, value: 3 },
            { id: 5, name: `${locales[value].internal_use}`, value: 4 },
            { id: 6, name: `${locales[value].before_30}`, value: 5 },
            { id: 7, name: `${locales[value].during}`, value: 6 },
        ]});

        //------------[start] timeSelected-------------------------
        const prevValue = this.prevLang.current.value
        let timeSelected = this.props.drugLabel.timeSelected;
        const keyOfTimeSelected = Object.keys(locales[prevValue]).filter(( key )=>{ 
            return timeSelected.includes(locales[prevValue][key] || null)
        })
        let newLangOfTimeSelected = []
        keyOfTimeSelected.forEach(key => {
            newLangOfTimeSelected = [...newLangOfTimeSelected,locales[value][key] || null]
        });
        this.props.setDrugLabelValue('timeSelected', newLangOfTimeSelected)
        //------------[end] timeSelected----------------------------
        
        const { labels }  = this.props.drugLabel.product;

        if (labels.length > 0) {
            let label = labels[0];
            let eat = '';
            eat += label.volume_intake ? ' ' + label.volume_intake + ' ' + label.unit_intake : '';
            eat += label.taken_time == 1 ? ` ${locales[value].before_meal}` : label.taken_time == 2 ? ` ${locales[value].after_meal}` : label.taken_time == 3 ? ` ${locales[value].external_use}` : '';
            eat += label.daily_frequency_intake ? ' ' + label.daily_frequency_intake + ' ' + locales[value].times : '';
            this.props.setDrugLabelValue('eat', eat);
            this.setState({disableReset: false})
        }
    }

    _onChangeTakeTime(e) {
        const { value } = e.target;
        this.props.setDrugLabelValue('taken_time', value)
        this.setState({disableReset: false})
    }

    _handleClickCheckBox(e) {
        const { name } = e.target
        this.props.selectTimeToEat(e.target.value)

        const checked = this.props.drugLabel[name]
        this.props.setDrugLabelValue(name, !checked)
        this.setState({disableReset: false})
    }

    _handleChangeDatePicker(date){

        this.setState({
            date: date
        })

        this.props.setDrugLabelValue('date',date.format('DD/MM/YYYY'));
        this.setState({disableReset: false})
    }

    _handleChangeExpDatePicker(date) {
        this.props.setDrugLabelValue('exp_date', date.format('DD/MM/YYYY'));
        this.setState({disableReset: false})
    }

    _handleDisplayStore(e) {
        const checked = this.props.drugLabel.display_store
        this.props.setDrugLabelValue('display_store', !checked)
        this.setState({disableReset: false})
    }

    _handleSaveLable(e) {
        const checked = this.props.drugLabel.save_label
        this.props.setDrugLabelValue('save_label', !checked)
        this.setState({disableReset: false})
    }

    _handleIncludeDrugInfo() {
        const checked = this.props.drugLabel.include_drug_info
        this.props.setDrugLabelValue('include_drug_info', !checked)
        this.setState({disableReset: false})
    }

    _handleChangeCheckbox(e) {
        const { name, value } = e.target
        const checked = this.props.drugLabel[name]
        this.props.setDrugLabelValue(name, !checked)
        this.setState({disableReset: false})
    }

    render() {
        const { showPrintDrugLabel,
            closePrintDrugLabel,
            language,
            settings,
            employee,
            drugLabel,
            submitPrintDrungLabel,
            resetPrintDrugLabel,
            customer,
        } = this.props

        const { taken_times } = this.state

        return (
            <Modal 
            className="modal--print-drung-label"
            backdrop="static"
            isOpen={showPrintDrugLabel} 
            size="lg"
            toggle={closePrintDrugLabel}
            style={{
                fontFamily: "'Kanit', sans-serif"
            }}
            >
                <ModalHeader toggle={closePrintDrugLabel}>
                    {language.print_label}
                    <input type="hidden" ref={this.prevLang} value={this.state.language} />
                    <select
                        className="form-control ml-3"
                        value={this.state.language}
                        onChange={this._onChangeLocal}
                    >
                        <option value="th">{language.thai}</option>
                        <option value="en">{language.english}</option>
                        <option value="cn">{language.chinese}</option>
                    </select>
                </ModalHeader>
                <ModalBody>
                    {/* Form view */}
                    <h5 className="mb-3">รูปแบบฉลาก</h5>
                    <div className="print-drung-label-grid">
                        <div className="print-drung-label-inner">
                            <table className="print-drung-label-table">
                                <thead className="d-none">
                                    <tr>
                                        <td></td>
                                        <td width="1%"></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            {drugLabel.display_store ? (
                                                <span>
                                                    <small>ร้านขายยา</small><br/>
                                                    <span style={{ width: "313px" }}>{settings.company.name}</span> <small style={{ width: "110px" }}>({settings.branch.name})</small><br/>
                                                    <small style={{ width: "469px" }}>{settings.branch.address}</small> <small style={{ width: "86px" }}>({ settings.branch.phone_no ? settings.branch.phone_no : '-'})</small>
                                                    <hr/>
                                                </span>
                                            ) : (
                                                <span>
                                                    <small>&nbsp;</small><br/>
                                                    <span style={{ width: "313px" }}>&nbsp;</span><br/>
                                                    <small style={{ width: "469px" }}>&nbsp;</small>
                                                    <hr/>
                                                </span>
                                            )}
                                      
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormGroup className='form-group--wrapper'>
                                                <Label htmlFor="name">{language.patient_name} : </Label>
                                                <TextareaAutosize
                                                    className='form-control no-resize' // ถ้าเกิน text limit ให้เพิ่ม class "is-overflow"
                                                    style={{width: "325px"}}
                                                    type="text"
                                                    name="patient_name"
                                                    value={customer.name ? customer.name : drugLabel.patient_name}
                                                    onChange={this._handleChangeInput}
                                                    disabled={customer.name}
                                                    ref='txtName'
                                                />
                                            </FormGroup>
                                        </td>
                                        <td>
                                            <FormGroup>
                                                <Label htmlFor="" >{language.date} : </Label>
                                                <div style={{width:"110px"}}>
                                                    <DatePicker
                                                        className="text-right"
                                                        selected={this.state.date}
                                                        onChange={this._handleChangeDatePicker}
                                                        dateFormat='DD/MM/YYYY'
                                                        readOnly
                                                    />
                                                </div>
                                            </FormGroup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormGroup>
                                                <Label htmlFor="drug_name" >{language.drug_name} :</Label>
                                                <Input
                                                    style={{width:"325px"}}
                                                    type="text"
                                                    name="drug_name"
                                                    value={drugLabel.drug_name}
                                                    onChange={this._handleChangeInput}
                                                    readOnly
                                                />
                                            </FormGroup>
                                        </td>
                                        <td>
                                            <FormGroup>
                                                <Label htmlFor="" >{language.exp_date} :</Label>
                                                <div style={{width:"110px"}}>
                                                    <DatePicker
                                                        className="text-right"
                                                        value={drugLabel.exp_date}
                                                        style={{width:"100%"}}
                                                        showYearDropdown={true}
                                                        onChange={this._handleChangeExpDatePicker}
                                                        dateFormat='DD/MM/YYYY'
                                                    />
                                                </div>
                                            </FormGroup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormGroup className='form-group--wrapper'>
                                                <Label htmlFor="dosage_instruction" >{language.dosage_instruction} :</Label>
                                                <TextareaAutosize
                                                    className='form-control no-resize'
                                                    style={{width:"325px", maxHeight: "62px"}}
                                                    type="text"
                                                    name="dosage_instruction"
                                                    value={drugLabel.dosage_instruction}
                                                    onChange={this._handleChangeInput}
                                                    maxLength={60}
                                                />
                                            </FormGroup>
                                        </td>
                                        <td>
                                            <FormGroup>
                                                <Label htmlFor="amount" >{language.amount} :</Label>
                                                <Input
                                                    style={{width:"54px"}}
                                                    className="text-center"
                                                    type="text"
                                                    name="amount"
                                                    onKeyPress={this.isOnlyNumber}
                                                    value={drugLabel.amount}
                                                    onChange={this._handleChangeInput}
                                                />
                                                <span className="ml-2">{drugLabel.amount_unit}</span>
                                            </FormGroup>
                                        </td>
                                    </tr>
                                    {drugLabel.is_medication_management ? 
                                        <tr>
                                            <td>
                                                <FormGroup className='form-group--wrapper'>
                                                    <Label htmlFor="how_to_use" >{language.how_to_use} :</Label>
                                                    <TextareaAutosize
                                                        className='form-control no-resize'
                                                        style={{width:"325px", maxHeight: "62px"}}
                                                        type="text"
                                                        name="how_to_use"
                                                        value={drugLabel.how_to_use}
                                                        onChange={this._handleChangeInput}
                                                        maxLength={60}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    : ''}
                                    <tr>
                                        <td>
                                            <div className="d-flex">
                                                <FormGroup className="d-inline-flex">
                                                    { drugLabel.taken_time == '3' || drugLabel.taken_time == '4' ?
                                                    (<Label htmlFor="volume_intake" >{language.volume_intake_external} :</Label>)
                                                    : (<Label htmlFor="volume_intake" >{language.volume_intake} :</Label>)}
                                                    
                                                    <TextareaAutosize
                                                        style={{width:"54px"}}
                                                        className="form-control no-resize text-center"
                                                        type="text"
                                                        name="volume_intake"
                                                        value={drugLabel.volume_intake}
                                                        onChange={this._handleChangeInput}
                                                    />
                                                </FormGroup>
                                                <FormGroup className="d-inline-flex ml-2 form-group--wrapper">
                                                    { 
                                                        drugLabel.taken_time == '3' || drugLabel.taken_time == '4'
                                                        ? (<Label htmlFor="unit_intake" >{language.unit_intake_external} :</Label>)
                                                        : (<Label htmlFor="unit_intake" >{language.unit_intake} :</Label>)
                                                    }
                                                    <TextareaAutosize
                                                        className="form-control no-resize"
                                                        style={{width:"117px"}}
                                                        type="text"
                                                        name="unit_intake"
                                                        value={drugLabel.unit_intake}
                                                        onChange={this._handleChangeInput}
                                                    />
                                                    <small className="text-muted ml-2" style={{ maxWidth: "100px" }}>{language.unit_explain}</small>
                                                </FormGroup>
                                            </div>
                                        </td>
                                        <td>
                                            <FormGroup>
                                                <Label htmlFor="daily_frequency_intake" >{language.daily_frequency_intake} :</Label>
                                                <TextareaAutosize
                                                    style={{width:"100px"}}
                                                    className="text-center form-control no-resize"
                                                    type="text"
                                                    name="daily_frequency_intake"
                                                    value={drugLabel.daily_frequency_intake}
                                                    onChange={this._handleChangeInput}
                                                />
                                                <span className="ml-2">{language.per_day}</span>
                                            </FormGroup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <div className="d-flex text-nowrap form-group">
                                                <FormGroup className="d-inline-flex mr-3 mb-0">
                                                    <Label htmlFor="" >{language.taken_time} :</Label>
                                                    <SelectFromGroup
                                                        className="mb-0"
                                                        name="taken_time"
                                                        value={drugLabel.taken_time}
                                                        onChange={this._onChangeTakeTime}
                                                        placeholder={''}
                                                        options={taken_times}
                                                    />
                                                </FormGroup>
                                                <FormGroup check inline className="ml-auto mr-3">
                                                    <Label check>
                                                        <Input
                                                            name="taken_morning"
                                                            type="checkbox"
                                                            value={language.morning}
                                                            onChange={this._handleClickCheckBox}
                                                            checked={ drugLabel.timeSelected.includes(language.morning)}
                                                        /> {language.morning}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check inline className="mr-3">
                                                    <Label check>
                                                        <Input
                                                            name="taken_noon"
                                                            type="checkbox"
                                                            value={language.noon}
                                                            onChange={this._handleClickCheckBox}
                                                            checked={ drugLabel.timeSelected.includes(language.noon)}
                                                        /> {language.noon}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check inline className="mr-3">
                                                    <Label check>
                                                        <Input
                                                            name="taken_evening"
                                                            type="checkbox"
                                                            value={language.evening}
                                                            onChange={this._handleClickCheckBox}
                                                            checked={ drugLabel.timeSelected.includes(language.evening)}
                                                        /> {language.evening}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check inline>
                                                    <Label check>
                                                        <Input
                                                            name="taken_before_bed"
                                                            type="checkbox"
                                                            value={language.before_bed}
                                                            onChange={this._handleClickCheckBox}
                                                            checked={ drugLabel.timeSelected.includes(language.before_bed)}
                                                        /> {language.before_bed}
                                                    </Label>
                                                </FormGroup>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <div className='d-flex'>
                                                <FormGroup className="d-inline-flex mr-3">
                                                    <FormGroup check inline>
                                                        <Label check className="text-nowrap">
                                                            <Input
                                                                type="checkbox"
                                                                name="is_taken_other"
                                                                value={language.every}
                                                                onChange={this._handleChangeCheckbox}
                                                                checked={ drugLabel.is_taken_other }
                                                            /> {language.every} :
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="mb-0 d-inline-flex">
                                                        <TextareaAutosize
                                                            className="ext-center form-control no-resize"
                                                            style={{ width: "45px" }}
                                                            name="taken_other"
                                                            value={drugLabel.taken_other}
                                                            onChange={this._handleChangeInput}
                                                        />
                                                        <span className="ml-2 text-nowrap">{language.hour}</span>
                                                    </FormGroup>
                                                </FormGroup>
                                                <FormGroup className="w-100">
                                                    <FormGroup check inline>
                                                        <Label check className="text-nowrap">
                                                            <Input
                                                                type="checkbox"
                                                                value="true"
                                                                name="taken_symptom_occur"
                                                                onChange={this._handleChangeCheckbox}
                                                                checked={ drugLabel.taken_symptom_occur }
                                                            /> {language.when_has_symptom} :
                                                        </Label>
                                                    </FormGroup>
                                                    <FormGroup className="mb-0 w-100 form-group--wrapper">
                                                        <TextareaAutosize
                                                            className='form-control no-resize'
                                                            style={{width:"100%",  height: "62px"}}
                                                            name="symptom_occur"
                                                            value={drugLabel.symptom_occur}
                                                            onChange={this._handleChangeInput}
                                                            maxLength={33}
                                                        />
                                                    </FormGroup>
                                                </FormGroup>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <FormGroup>
                                                <FormGroup check inline>
                                                    <Label check className="text-nowrap">
                                                        <Input
                                                            type="checkbox"
                                                            name="is_note"
                                                            value="true"
                                                            onChange={this._handleChangeCheckbox}
                                                            checked={drugLabel.is_note}
                                                        /> {language.note}
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup className="mb-0 w-100 form-group--wrapper">
                                                    <TextareaAutosize
                                                        className='form-control no-resize'
                                                        style={{width:"100%", maxHeight: "62px"}}
                                                        name="note"
                                                        value={drugLabel.note}
                                                        onChange={this._handleChangeInput}
                                                        maxLength={60}
                                                    />
                                                </FormGroup>
                                            </FormGroup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <FormGroup check style={{ marginTop: '0.45rem' }}>
                                                <Label check>
                                                    <Input
                                                        name="show_pharmacist"
                                                        type="checkbox"
                                                        value="true"
                                                        onChange={this._handleChangeCheckbox}
                                                        checked={drugLabel.show_pharmacist}
                                                    /> {language.show_pharmacist}
                                                </Label>
                                            </FormGroup>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <FormGroup className="mb-0">
                                                <Label htmlFor="pharmacist" >{this.state.locales.pharmacist} :</Label>
                                                <Input
                                                    style={{width:"100%"}}
                                                    name="pharmacist"
                                                    placeholder={language.name}
                                                    value={`${employee.employee.first_name} ${employee.employee.last_name}`}
                                                    onChange={this._handleChangeInput}
                                                    readOnly
                                                />
                                            </FormGroup>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="print-drung-label-inner">
                            <FormGroup className="d-flex flex-column align-items-start h-100">
                                <Label htmlFor="drug_label_information">{language.more_info} :</Label>
                                <Input className="flex-grow-1 border-0 p-0" 
                                    type="textarea"
                                    name="drug_label_information"
                                    value={drugLabel.drug_label_information} 
                                    onChange={this._handleChangeInput} 
                                />
                            </FormGroup>
                        </div>
                    </div>

                    {/* Print view */}
                    <div 
                        id='print-drug-label' 
                        style={{ 
                            display: "none",
                            fontFamily: "Roboto, system-ui, sans-serif",
                            lineHeight:"18px",
                        }}
                    >
                        <div
                            style={{
                                width: "100%",
                                borderWidth: "0",
                                padding: "0",
                                margin: "0 auto"
                            }}
                        >
                            <table 
                                style={{
                                    width:"100%",
                                    fontSize: fontSizeForPrintDetail,
                                    marginTop: "0"
                                }}
                            >
                                <thead>
                                    <tr>
                                        <th style={{ padding: "0px"}}></th>
                                        <th width="110px" style={{ padding: "0px"}}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="2">
                                            {drugLabel.display_store ? 
                                                (
                                                    <span>
                                                        <small style={{ fontSize: fontTitleSmallPrintDetail }}>
                                                            ร้านขายยา
                                                        </small><br/>
                                                        <span style={{ width: "157px", fontSize: fontTitlePrintDetail }}>
                                                            {settings.company.name}
                                                        </span>&nbsp;
                                                        <small style={{ fontSize: fontTitleSmallPrintDetail, width: "59px" }}>
                                                            ({settings.branch.name})
                                                        </small><br/>
                                                        <small style={{ fontSize: fontTitleSmallPrintDetail, width: "235px" }}>
                                                            {settings.branch.address}&nbsp;
                                                            <span style={{ width: "44px" }}>
                                                                ({employee.phone ? employee.phone : '-'})
                                                            </span>
                                                        </small>
                                                    </span>
                                                ) : (
                                                    <span>
                                                        <small style={{ fontSize: "px" }}>&nbsp;</small><br/>
                                                        <span>&nbsp;</span><br/>
                                                        <small style={{ fontSize: "6px" }}>&nbsp;</small>
                                                    </span>
                                                )
                                            }
                                            <hr/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={printGroup}>
                                                <span style={printLabel}>{language.patient_name} :</span>
                                                <span style={printValue}>{customer.name ? customer.name : drugLabel.patient_name}</span>
                                            </div>
                                        </td>
                                        <td style={{ display: "flex", justifyContent: "space-between" }}>{language.date} : <span>{drugLabel.date}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span style={{ minWidth: "35px", display: "inline-block" }}>{language.drug_name} : </span>{drugLabel.drug_name}</td>
                                        <td style={{ display: "flex", justifyContent: "space-between" }}>
                                            <span style={{ fontSize: fontTitleSmallPrintDetail}}>{language.exp_date}&nbsp;:</span> 
                                            <span>{drugLabel.exp_date}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div style={printGroup}>
                                                <span style={printLabel}>{language.dosage_instruction} :</span>
                                                <span style={printValue}>{drugLabel.dosage_instruction}</span>
                                            </div>
                                        </td>
                                        <td style={{ display: "flex", justifyContent: "space-between" }}>{language.amount} : <span>{drugLabel.amount}</span> <span>{drugLabel.amount_unit}</span></td>
                                    </tr>
                                    { drugLabel.is_medication_management ? (
                                         <tr>
                                            <td>
                                                <div style={printGroup}>
                                                    <span style={printLabel}>{language.how_to_use} :</span>
                                                    <span style={printValue}>{drugLabel.how_to_use}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : ''}
                                    <tr>
                                        <td>
                                            <div style={printGroupLg}>
                                                {
                                                    drugLabel.taken_time == '3' || drugLabel.taken_time == '4'
                                                    ?(<span style={printLabel}>{language.volume_intake_external} : {drugLabel.volume_intake}</span>)
                                                    :(<span style={printLabel}>{language.volume_intake} : {drugLabel.volume_intake}</span>)
                                                }
                                                <span style={printValue}>{drugLabel.unit_intake}</span>
                                            </div>
                                        </td>
                                        <td style={{ display: "flex", justifyContent: "space-between" }}>{language.daily_frequency_intake} : <span>{drugLabel.daily_frequency_intake}</span> <span>{language.per_day}</span></td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <div style={{ display: "flex", flexWrap: "wrap", whiteSpace: "nowrap" }} className="taken-choice">
                                                {
                                                    drugLabel.timeSelected.length ? (
                                                        <span style={{marginRight: "15px"}}>{language.taken_time} : {taken_times[drugLabel.taken_time].name}</span>
                                                    ): ('')
                                                }
                                                <span>
                                                    { 
                                                        drugLabel.timeSelected.includes(language.morning) 
                                                        ? (
                                                            <span>
                                                                <img style={printIconCheckBlack} src={iconCheckBlack} alt=""  />
                                                                <label>{language.morning}</label>
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                    &nbsp;&nbsp;
                                                </span>
                                                
                                                <span>
                                                    { 
                                                        drugLabel.timeSelected.includes(language.noon) 
                                                        ? (
                                                            <span>
                                                                <img style={printIconCheckBlack} src={iconCheckBlack} alt=""  />
                                                                <label>{language.noon}</label>
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                    &nbsp;&nbsp;
                                                </span>

                                                <span>
                                                    { 
                                                        drugLabel.timeSelected.includes(language.evening) 
                                                        ? (
                                                            <span>
                                                                <img style={printIconCheckBlack} src={iconCheckBlack} alt=""  />
                                                                <label>{language.evening}</label>
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                    &nbsp;&nbsp;
                                                </span>
                                                
                                                <span>
                                                    { 
                                                        drugLabel.timeSelected.includes(language.before_bed) 
                                                        ? (
                                                            <span>
                                                                <img style={printIconCheckBlack} src={iconCheckBlack} alt=""  />
                                                                <label>{language.before_bed}</label>
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )
                                                    }
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <div style={printGroupLg}>
                                                <span>
                                                    {
                                                        drugLabel.is_taken_other
                                                        ? ( <img style={printIconCheckBlack} src={iconCheckBlack} alt=""  /> )
                                                        : ( '') 
                                                    }
                                                    {
                                                        drugLabel.is_taken_other
                                                        ? (<label>
                                                            {language.every}&nbsp;:&nbsp;
                                                            <span style={{minWidth: "11px", display: "inline-block"}}>
                                                                {drugLabel.is_taken_other ? drugLabel.taken_other + " " + language.hour : "-"}
                                                            </span>
                                                        </label>) : ('')
                                                    }
                                                </span>

                                                <span>
                                                    {
                                                        drugLabel.taken_symptom_occur
                                                        ? ( <img style={printIconCheckBlack} src={iconCheckBlack} alt=""  /> )
                                                        : ( '') 
                                                    }
                                                    {
                                                        drugLabel.taken_symptom_occur
                                                        ? (<label style={{ fontSize: fontTitleSmallPrintDetail}}>
                                                                {language.when_has_symptom}&nbsp;:&nbsp;
                                                            </label>
                                                          ): ('')
                                                    }
                                                    <span style={printValue}>
                                                        {drugLabel.taken_symptom_occur ? drugLabel.symptom_occur : ""}
                                                    </span>
                                                    
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <div style={printGroup}>
                                                <span>
                                                    {
                                                        drugLabel.is_note
                                                        ? ( <img style={printIconCheckBlack} src={iconCheckBlack} alt=""  /> )
                                                        : ('') 
                                                    }
                                                    {
                                                        drugLabel.is_note
                                                        ?(<label style={{ fontSize: fontTitleSmallPrintDetail}}>
                                                            { language.note }&nbsp;:&nbsp;
                                                        </label>): ('')
                                                    }
                                              
                                                    <span style={printValue}>
                                                        { drugLabel.is_note ? drugLabel.note : ""}
                                                    </span>
                                                    
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                    {
                                        drugLabel.show_pharmacist
                                        ? (
                                            <tr>
                                                <td colSpan="2">
                                                    {this.state.locales.pharmacist}&nbsp;:&nbsp;
                                                    <span>{`${employee.employee.first_name} ${employee.employee.last_name}`}</span>
                                                </td>
                                            </tr>
                                        ) : undefined
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                        {drugLabel.include_drug_info ? (<div
                            style={{
                                width: "100%",
                                borderWidth: "0",
                                borderStyle: "solid",
                                borderColor: "#007bff",
                                borderRadius: "4px",
                                padding: "0",
                                margin: "0 auto",
                                marginTop: 20,
                                pageBreakBefore: "always"
                            }}
                        >
                            <div>
                                <span style={{
                                    width:"100%",
                                    fontFamily: "Roboto, system-ui, sans-serif",
                                    fontSize: fontSizeForPrintDetail,
                                    lineHeight:"18px",
                                    whiteSpace: "pre-line"
                                }}> {language.more_info} : <br/>
                                { drugLabel.drug_label_information }
                                </span>
                            </div>
                        </div>) : (<label></label>)}
                    </div>

                    <Row className="align-items-center">
                        <Col className="py-4">
                            <FormGroup check>
                                <Label check>
                                <Input type="checkbox" 
                                    id="display_store"
                                    value="true"
                                    name="display_store"
                                    checked={!drugLabel.display_store} 
                                    onChange={this._handleChangeCheckbox} 
                                    />{' '}
                                ไม่เเสดงชื่อเเละที่อยุ่ร้าน
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label 
                                    check 
                                    style={
                                        saveLabel.disabled
                                        ? { color: saveLabel.styleColor} 
                                        : {}
                                    }
                                    >
                                    <Input 
                                        type="checkbox"
                                        value="true"
                                        name="save_label"
                                        disabled={saveLabel.disabled}
                                        checked={drugLabel.save_label}
                                        onChange={this._handleSaveLable}
                                        id="checkbox2" 
                                        />{' '}
                                    บันทึกข้อมูลฉลากยา&nbsp;
                                    <span style={{ color: saveLabel.styleColor }}>
                                        {saveLabel.remark}
                                    </span>
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check>
                                <Input 
                                    type="checkbox"
                                    value="true"
                                    name="include_drug_info"
                                    checked={drugLabel.include_drug_info}
                                    onChange={this._handleIncludeDrugInfo}
                                    id="checkbox3" 
                                    />{' '}
                                พิมพ์ “ข้อมูลยาเพิ่มเติม” ร่วมกับฉลากยา
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm="auto">
                            <Button type="button" outline color="warning" onClick={(e) => {  e.preventDefault; resetPrintDrugLabel(); }} disabled={this.state.disableReset}>{language.reset}</Button>
                            <Button outline color="danger" className="mx-2" onClick={closePrintDrugLabel}>{language.close}</Button>
                            <Button color="primary" onClick={(e) => {  e.preventDefault; submitPrintDrungLabel(); }}>{drugLabel.save_label ? language.print_and_save : language.print }</Button>{' '}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
                                                        )
                                                    }


    componentWillReceiveProps(nextProps) {
        if (!nextProps.showPrintDrugLabel) {
            const language = localStorage.getItem('language') || 'th';
            this.setState({
                showPharmacist: true,
                language,
                defaultLang: language,
                locales: locales[language]
            });
        }
    }

    componentDidUpdate (prevProps,prevState) {
        const { drugLabel, setDrugLabelValue, language, selectTimeToEat, focus, cart, employee} = this.props;
        const { drugLabel: prevDrugLabel, focus: preFocus } = prevProps;

        if (drugLabel.product.id !== prevDrugLabel.product.id) {
            const { product } = drugLabel
            const { labels }  = product
            const { details } = cart
            const productInCart = details.find(detail => {
                return detail.product.id === product.id
            })

            if (productInCart !== undefined) {
                const {sales_quantity} = productInCart
                const {unit} = productInCart
                setDrugLabelValue('amount', sales_quantity)
                setDrugLabelValue('amount_unit', unit.name)  
            }

            const exp_date = product.expire_date ? 
                moment(product.expire_date, 'YYYY-MM-DD').format('DD/MM/YYYY') 
                : ''
            setDrugLabelValue('drug_name', product.name)
            setDrugLabelValue('exp_date', exp_date)
            this.props.setDrugLabelValue('date', moment().format(dateFormate))            
            setDrugLabelValue('is_medication_management', false)

            if (labels.length > 0){
                let label = labels[0]
                const initialValue = [
                    'id',
                    'volume_intake',
                    'unit_intake',
                    'daily_frequency_intake',
                    'is_taken_other',
                    'taken_other',
                    'taken_time',
                    'taken_morning',
                    'taken_noon',
                    'taken_evening',
                    'taken_before_bed',
                    'taken_symptom_occur',
                    'symptom_occur',
                    'is_note',
                    'note',
                    'dosage_instruction',
                    'drug_label_information',
                    'created_at',
                ]
                for (const [key, value] of Object.entries(label)) {
                    if (initialValue.includes(key)) {
                        setDrugLabelValue(key, value)
                    }
                }
       
                let eat = '';
                eat += label.volume_intake ? ' ' + label.volume_intake + ' ' + label.unit_intake : '';
                eat += label.taken_time == 1 ? ` ${this.state.locales.before_meal}` : label.taken_time == 2 ? ` ${this.state.locales.after_meal}` : label.taken_time == 3 ? ` ${this.state.locales.external_use}` : '';
                eat += label.daily_frequency_intake ? ' ' + label.daily_frequency_intake + ' ' + this.state.locales.times : '';
                setDrugLabelValue('eat', eat);

                if(label.taken_morning){ selectTimeToEat(language.morning) }
                if(label.taken_noon){ selectTimeToEat(language.noon) }
                if(label.taken_evening){ selectTimeToEat(language.evening) }
                if(label.taken_before_bed){ selectTimeToEat(language.before_bed) }
                if(label.taken_symptom_occur){ selectTimeToEat(language.when_symptoms_occur) }

                let remark = '';
                remark += (label.note)? label.note : '';
                remark += (label.dosage_instruction)? ' '+label.dosage_instruction :'';
                remark += (label.taken_other) ? ' '+label.taken_other : ' ';
                setDrugLabelValue('remark', remark);
            }

            if(product.medicationManagements) {
                setDrugLabelValue('is_medication_management', true);
                let moreInfo = '';
                const limits = {
                    dosage_instruction: 60,
                }

                if(product.medicationManagements.indication && product.medicationManagements.indication.length) {
                    if(product.medicationManagements.indication.length <= limits.dosage_instruction) {
                        setDrugLabelValue('dosage_instruction', product.medicationManagements.indication)
                    } else {
                        setDrugLabelValue('dosage_instruction', null)
                        moreInfo += `${language.dosage_instruction} : ${product.medicationManagements.indication} \n`;
                    }
                }
    
                if(product.medicationManagements.how_to_use && product.medicationManagements.how_to_use.length) {
                    if(product.medicationManagements.how_to_use.length <= limits.dosage_instruction) {
                        setDrugLabelValue('how_to_use', product.medicationManagements.how_to_use)   
                    } else {
                        setDrugLabelValue('how_to_use', null)
                        moreInfo += `${language.how_to_use} : ${product.medicationManagements.how_to_use} \n`;
                    }
                }
    
                let volume_intake = '';
                if(product.medicationManagements.dosage_from) {
                    volume_intake += product.medicationManagements.dosage_from;
    
                    if(product.medicationManagements.dosage_to) {
                        volume_intake += ' - ' + product.medicationManagements.dosage_to;
                    }
    
                    setDrugLabelValue('volume_intake', volume_intake);
                }
    
                if(product.medicationManagements.dosage_unit) {
                    setDrugLabelValue('unit_intake', product.medicationManagements.dosage_unit);
                }
    
                let daily_frequency_intake = '';
                if(product.medicationManagements.frequency_from) {
                    daily_frequency_intake += product.medicationManagements.frequency_from;
    
                    if(product.medicationManagements.frequency_to) {
                        daily_frequency_intake += ' - ' + product.medicationManagements.frequency_to;
                    }
    
                    setDrugLabelValue('daily_frequency_intake', daily_frequency_intake);
                }

                if(product.medicationManagements.timeSelected) {
                    setDrugLabelValue('timeSelected', product.medicationManagements.timeSelected)
                }

                if(product.medicationManagements.every) {
                    setDrugLabelValue('is_taken_other', true)
                    setDrugLabelValue('taken_other', product.medicationManagements.every);
                } else {
                    setDrugLabelValue('is_taken_other', false)
                }

                if(product.medicationManagements.notes) {
                    setDrugLabelValue('is_note', true)
                    setDrugLabelValue('note', product.medicationManagements.notes)
                }

                if(product.medicationManagements.taken_time) {
                    setDrugLabelValue()
                    setDrugLabelValue('taken_time', product.medicationManagements.taken_time)
                }

                if(moreInfo.length > 0) {
                    setDrugLabelValue('include_drug_info', true);
                    setDrugLabelValue('drug_label_information', moreInfo)
                }
            }

            if (employee.employee.is_pharmacist && !product.medicationManagements) {
                saveLabel.disabled  = false;
                saveLabel.remark    = "(ข้อมูลที่จะไม่ถูกบันทึก : ชื่อผู้ใช้ยา, วันหมดอายุ, จำนวน)";
            } else {
                saveLabel.disabled  = true;
                saveLabel.remark    = "(เฉพาะเภสัชกรเท่านั้น)";
                setDrugLabelValue('save_label', false);
            }
        }

        setTimeout(() => {
            if( !preFocus && focus){
             
                ReactDOM.findDOMNode(this.refs.txtName).focus();
            }
        })
    }

}

export default PrintDrugLabel