import React from 'react'
import { Modal, ModalBody, Row, Col, Button } from 'reactstrap'

class NotiHatoHubSyncErrorModal extends React.Component {
    render() {
        const { ShowHatohubNotiSyncData, language, closeNotiSyncData } = this.props
        return (
            <Modal isOpen={ShowHatohubNotiSyncData}
                style={{ maxWidth: '40%' }}>
                <ModalBody className="px-4">
                    <div className="cloud-alert-img"></div>
                    <h5 className="text-center my-4" style={{ color: '#ff6eaf'}}><u>{language.warning}</u></h5>
                    <p className="text-danger text-center mt-4 mb-5" style={{ fontSize: '16px' }}>{language.hatohub_sync_error}</p>
                    <Row className="my-2">
                        <Col md="6">
                            <Button
                                color="primary"
                                onClick={closeNotiSyncData}
                                block>
                                {language.noti_sync_later}
                            </Button>
                        </Col>
                        <Col md="6">
                        <Button color="danger" onClick={ this.props.onClose}>{ this.props.language.cancel}</Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        )
    }
}

export default NotiHatoHubSyncErrorModal