import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Form } from "reactstrap";
import { FaInfoCircle } from "react-icons/fa"; // Import icon for error

const MedAdherenceModal = ({ show, value, onChange, onSubmit, onClose }) => {
  const [error, setError] = useState(""); // Store validation error
  const [isValid, setIsValid] = useState(true); // Track if input is valid

  const styles = {
    input: {
      width: "100%",
      padding: "10px",
      border: isValid ? "1px solid #ddd" : "2px solid red",
      borderRadius: "8px",
      marginBottom: "5px",
      fontSize: "16px",
      fontWeight: "bold",
      color: "#000",
      display: "flex",
      alignItems: "center",
    },
    errorText: {
      color: "red",
      fontSize: "14px",
      marginBottom: "15px",
    },
    buttonSkip: {
      border: "1px solid #ddd",
      backgroundColor: "white",
      color: "#000",
      borderRadius: "8px",
      padding: "10px 20px",
      marginRight: "10px",
      width: "150px",
    },
    buttonSubmit: {
      backgroundColor: "#4caf50",
      color: "#fff",
      border: "none",
      borderRadius: "8px",
      padding: "10px 20px",
      width: "300px",
    },
    inputContainer: {
      position: "relative",
    },
    errorIcon: {
      position: "absolute",
      right: "10px",
      top: "50%",
      transform: "translateY(-50%)",
      color: "red",
    },
    tooltip: {
      position: "absolute",
      right: "-50px",
      top: "-10px",
      backgroundColor: "#4285F4",
      color: "white",
      padding: "10px 10px",
      borderRadius: "5px",
      fontSize: "14px",
      whiteSpace: "nowrap",
    },
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const API_V2_URL = process.env.API_V2_URL;

    if (!value.trim()) {
        setError("กรุณากรอกข้อมูล!"); // Show an error if input is empty
        setIsValid(false);
        return;
      }
    setError(""); // Reset error state
    try {
      const response = await fetch(`${API_V2_URL}/api/medical-adherence/pos/check-phone-number`, {
        method: "POST",
        headers: { "Content-Type": "application/json"},
        body: JSON.stringify({ phone: value }),
      });

      if (response.ok) {
        setIsValid(true);
        onSubmit(); // Proceed with form submission
      } else {
        setIsValid(false);
        setError("เบอร์นี้ยังไม่ได้ลงทะเบียน");
      }
    } catch (err) {
      setIsValid(false);
      setError("เกิดข้อผิดพลาดในการตรวจสอบ กรุณาลองใหม่");
    }
  };

  return (
    <Modal isOpen={show} size="md" centered>
      <ModalHeader>สำหรับลูกค้า Medical Adherence</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <label>เบอร์โทร</label>
          <div style={styles.inputContainer}>
            <input
            type="text"
            style={styles.input}
            pattern="[0-9]*"
            inputMode="numeric"
            placeholder="กรุณาใส่ตัวเลข"
            value={value}
            onChange={(e) => onChange(e.target.value.replace(/\D/g, ""))}
            />
            {!isValid && <FaInfoCircle style={styles.errorIcon} />} 
          </div>
          {!isValid && <div style={styles.tooltip}>{error}</div>}

          <div style={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            style={styles.buttonSkip}
            onClick={() => {
                onChange(""); // Reset input
                setError(""); // Clear error message
                setIsValid(true); // Reset validation
                onClose();
            }}
            >
            ข้าม
          </Button>

            <Button type="submit" style={styles.buttonSubmit}>
              ตรวจสอบ
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default MedAdherenceModal;
